/*
Template: 		REEN – Made for Designers
Description: 	Creative Portfolio Bootstrap 3 Template
Version: 		1.5
Author: 		fuviz
Demo URI: 		http://demo.fuviz.com/reen
*/

/*===================================================================================*/
/*	GENERAL
/*===================================================================================*/

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:focus {
  outline: 0 !important;
}
figure {
  margin: 0;
  padding: 0;
}
figure img {
  width: 100%;
  height: auto;
}
figure :not(.icon-overlay) a:hover,
figure:not(.icon-overlay) > a:hover {
  opacity: .7;
}
img[src*=".svg"] {
  height: 100%;
}
iframe {
  border: none;
}
* > p:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
::selection {
  background: #AAF2E4; /* Safari */
  color: #506A85;
}
::-moz-selection {
  background: #AAF2E4; /* Firefox */
  color: #506A85;
}
.animate {
  transition: all 0.1s ease-out; /* Optionally cubic-bezier(0.215, 0.610, 0.355, 1.000) which is easeOutCubic */
  -webkit-transform: translateZ(0); /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
  -webkit-backface-visibility: hidden; /* Fixes transition flickering in Chrome and Safari */
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  //background-color: #283645;
  color: #73879C;
  font-size: 18px;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  /*-moz-osx-font-smoothing: grayscale;*/
  text-rendering: optimizeLegibility;
  overflow-x: hidden !important;
}
.navbar,
main {
  background-color: #FFF;
}
main:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: #ffffff;
  transition: 0.4s ease-out 0.2s;
  transition-property: opacity, visibility;
}
main.js-reveal:before {
  opacity: 0;
  visibility: hidden;
}
.container {
  padding-right: 0;
  padding-left: 0;
}
p {
  margin-bottom: 20px;
}
p a {
  border-bottom: 1px solid #73879C;
}
p a:hover {
  border-color: transparent;
}
a {
  color: inherit;
  transition: all 0.2s ease-out;
  -webkit-transform: translateZ(0); /* Enables hardware accelerated transitions for transform, opacity and filter properties on browsers with webkit engines — optionally will-change */
  -webkit-backface-visibility: hidden; /* Fixes transition flickering in Chrome and Safari */
}
a:hover,
a:focus {
  color: $green;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 0.025em;
  color: #506A85;
  margin-top: 0;
  /*word-wrap: break-word;*/
}
h1 {
  font-size: 44px;
  line-height: 130%;
  margin-bottom: 25px;
}
h2 {
  font-size: 26px;
  line-height: 130%;
  margin-bottom: 20px;
}
h3 {
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 15px;
}
h4 {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 15px;
}
h5 {
  font-size: 14px;
  line-height: 160%;
  margin-bottom: 13px;
}
h6 {
  font-size: 12px;
  line-height: 160%;
  margin-bottom: 10px;
}
/*a .logo {
	transition: all 0.2s ease-out;
}*/
//a:hover .logo {
//  opacity: .6;
//}
a.green:hover {
  background: $green !important;
}
a.blue:hover {
  background: #3F8DBF !important;
}
a.red:hover {
  background: #FA6C65 !important;
}
a.orange:hover {
  background: #F27A24 !important;
}
a.purple:hover {
  background: #9B59B6 !important;
}
a.pink:hover {
  background: #D487BE !important;
}
a.navy:hover {
  background: #34495E !important;
}
a.gray:hover {
  background: #95A5A6 !important;
}
ol {
  position: relative;
  padding-left: 22px;
}
ul {
  list-style: none;
  position: relative;
  padding-left: 22px;
}
ul.arrowed li,
ul.circled li,
ol li {
  margin-bottom: 5px;
}
ul.arrowed li:before {
  font-family: 'fontello';
  font-size: 75%;
  font-weight: normal;
  color: $green;
  vertical-align: baseline;
  content: '\e846';
  margin-top: 1px;
  margin-left: -20px;
  position: absolute;
}
ul.circled li:before {
  font-family: 'fontello-circle';
  content: '\e800';
  font-size: 8px;
  font-weight: normal;
  color: $green;
  padding-top: 1px;
  margin-left: -20px;
  vertical-align: top;
  position: absolute;
}
ul.contacts {
  padding-left: 0;
}
ul.contacts li {
  margin-bottom: 5px;
}
footer ul.contacts li {
  margin-bottom: 2px;
}
.social-network {
  position: relative;
  z-index: 0;
  padding-top: 25px;
}
.social-network .social li {
  display: table-cell;
}
.social-network .social li a i {
  width: 55px;
  height: 80px;
  font-size: 20px;
}
#hero h1 {
  font-size: 78px;
  line-height: 110%;
  font-weight: 900;
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: font 200ms ease-out;
  -moz-transition: font 200ms ease-out;
  -o-transition: font 200ms ease-out;
  transition: font 200ms ease-out;
}
#hero #owl-main .caption h1.light-bg,
#hero #owl-main .caption h1.medium-bg,
#hero #owl-main .caption h1.dark-bg,
#hero #owl-main .caption h1.tint-bg {
  font-size: 73px;
}
#hero h4 {
  font-size: 33px;
  line-height: 120%;
}
#hero p {
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: .015em;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 0;
  -webkit-transition: font 200ms ease-out, margin 200ms ease-out;
  -moz-transition: font 200ms ease-out, margin 200ms ease-out;
  -o-transition: font 200ms ease-out, margin 200ms ease-out;
  transition: font 200ms ease-out, margin 200ms ease-out;
}
#hero .btn {
  margin-top: 60px;
  margin-bottom: 0;
  -webkit-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  -moz-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  -o-transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
  transition: background 200ms ease-out, font 200ms ease-out, padding 200ms ease-out, margin 200ms ease-out;
}
#hero .btn-large {
  font-size: 18px;
  padding: 18px 70px 20px;
}
header p {
  font-size: 22px;
  line-height: 150%;
}
p + h2,
p + h3 {
  padding-top: 15px;
}
.text-small {
  font-size: 16px;
  line-height: 160%;
}
.text-normal {
  font-size: inherit;
  line-height: inherit;
}
h2 + .text-small {
  margin-top: -5px;
}
header h2 + .text-small {
  margin-top: 0;
}
.post-title {
  margin: 0 0 15px 0;
}
h1.post-title {
  font-size: 22px;
  line-height: 24px;
}
.bordered .post-title {
  margin-bottom: 5px;
}
main header + * {
  margin-top: 35px !important;
}
.inner {
  padding-top: 120px;
  padding-bottom: 120px;
}
.inner-md {
  padding-top: 100px;
  padding-bottom: 100px;
}
.inner-sm {
  padding-top: 80px;
  padding-bottom: 80px;
}
.inner-xs {
  padding-top: 40px;
  padding-bottom: 40px;
}
.inner-top {
  padding-top: 120px;
}
.inner-top-md {
  padding-top: 100px;
}
.inner-top-sm {
  padding-top: 80px;
}
.inner-top-xs {
  padding-top: 40px;
}
.inner-bottom {
  padding-bottom: 120px;
}
.inner-bottom-md {
  padding-bottom: 100px;
}
.inner-bottom-sm {
  padding-bottom: 80px;
}
.inner-bottom-xs {
  padding-bottom: 40px;
}
.inner-left {
  padding-left: 75px;
}
.inner-left-md {
  padding-left: 60px;
}
.inner-left-sm {
  padding-left: 45px;
}
.inner-left-xs {
  padding-left: 30px;
}
.inner-right {
  padding-right: 75px;
}
.inner-right-md {
  padding-right: 60px;
}
.inner-right-sm {
  padding-right: 45px;
}
.inner-right-xs {
  padding-right: 30px;
}
.outer {
  margin-top: 120px;
  margin-bottom: 120px;
}
.outer-md {
  margin-top: 100px;
  margin-bottom: 100px;
}
.outer-sm {
  margin-top: 80px;
  margin-bottom: 80px;
}
.outer-xs {
  margin-top: 40px;
  margin-bottom: 40px;
}
.outer-top {
  margin-top: 120px;
}
.outer-top-md {
  margin-top: 100px;
}
.outer-top-sm {
  margin-top: 80px;
}
.outer-top-xs {
  margin-top: 40px;
}
.outer-bottom {
  margin-bottom: 120px;
}
.outer-bottom-md {
  margin-bottom: 100px;
}
.outer-bottom-sm {
  margin-bottom: 80px;
}
.outer-bottom-xs {
  margin-bottom: 40px;
}
.center-block {
  float: none;
}
.height-xs {
  height: 30vh;
}
.height-sm {
  height: 44vh;
}
.height-md {
  height: 69vh;
}
.height-lg {
  height: 90vh;
}
.dropcap {
  float: left;
  display: inline-block;
  width: 44px;
  height: 44px;
  background: #F2F5F7;
  font-size: 28px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  color: #506A85;
  margin: 6px 15px 0 0;
}
.dropcap-lg {
  float: left;
  display: inline-block;
  width: 70px;
  height: 70px;
  background: #F2F5F7;
  font-size: 48px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  color: #506A85;
  margin: 6px 15px 0 0;
}
blockquote {
  padding: 0;
  margin: 0;
  border: none;
}
blockquote p {
  font-size: 22px;
  line-height: 160%;
  font-style: italic;
}
blockquote footer,
blockquote small,
blockquote .small {
  color: #506A85;
  font-size: 14px;
  line-height: 150%;
  font-weight: 700;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before,
blockquote footer:after,
blockquote small:after,
blockquote .small:after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 1px;
  background-color: #506A85;
  margin-bottom: .28em;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  margin-right: 10px;
}
blockquote footer:after,
blockquote small:after,
blockquote .small:after {
  margin-left: 10px;
}
hr {
  border: 0;
  border-bottom: 1px solid #CED7E0;
  margin: 60px 0;
}
.map iframe {
  display: block;
  max-width: 100%;
}
h1 + figure,
p + figure {
  padding-top: 15px;
}
h2 + figure,
h3 + figure,
h2 + .thumbs,
h3 + .thumbs,
h2 + .thumb,
h3 + .thumb {
  padding-top: 10px;
}
h4 + figure {
  padding-top: 5px;
}
h2 + .owl-carousel,
p + .owl-carousel,
h2 + .panel-group,
p + .panel-group {
  padding-top: 20px;
}
figure figcaption {
  font-size: 14px;
  line-height: 150%;
}
figure figcaption h4 {
  margin-bottom: 10px;
}
figure * + figcaption {
  padding-top: 20px;
}
figure + *,
.thumb + figure {
  padding-top: 25px;
}
.classic-blog figure + * {
  padding-top: 35px;
}
figure.post-media + * {
  padding-top: 0;
}
.member .icon-overlay {
  display: inline-block;
}
.member .icon-overlay span {
  border-radius: 50%;
}
.member .img-circle {
  max-width: 200px;
  max-height: 200px;
}
.member .member-image {
  position: relative;
}
.member figcaption {
  position: relative;
  padding-top: 35px;
  z-index: 0;
}
.member figcaption.bordered {
  padding: 20px 15px 15px;
}
.member figcaption h2 span,
.member figcaption h3 span {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.015em;
}
.member figcaption h3 {
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 10px;
}
.member figcaption h2 span {
  font-size: 18px;
  line-height: 140%;
  margin-top: 5px;
}
.member figcaption h3 span {
  font-size: 14px;
  line-height: 120%;
  margin-top: 4px;
}
.member figcaption blockquote {
  font-size: 16px;
  font-style: italic;
  line-height: 160%;
  margin-bottom: 20px;
}
.member figcaption blockquote:before {
  display: inline;
  position: relative;
  content: "\201C";
  font-family: inherit;
  font-size: inherit;
  margin-right: 2px;
}
.member figcaption blockquote:after {
  content: "\201D";
  margin-left: 2px;
}
.member .social {
  display: inline-block;
}
.member .social li {
  display: table-cell;
}
.member .social li a i {
  width: 33px;
  height: 45px;
  font-size: 15px;
}
.member .text-overlay .info .social li a i {
  color: #FFF;
}
.member .text-overlay .info .social li a:hover .icon-s-facebook {
  color: #3B5998;
  color: #4668B3;
}
.member .text-overlay .info .social li a:hover .icon-s-gplus {
  color: #DD4B39;
  color: #DE5745;
}
.member .text-overlay .info .social li a:hover .icon-s-twitter {
  color: #00ACED;
  color: #2FBBED;
}
.member .text-overlay .info .social li a i:after {
  background: #FFF;
}
.img-bg,
.img-bg-center {
  background-position: center !important;
  background-size: cover;
}
.img-bg-top {
  background-position: top !important;
  background-size: cover;
}
.img-bg-bottom {
  background-position: bottom !important;
  background-size: cover;
}
.img-bg-soft,
.img-bg-softer {
  background-position: center;
  position: relative;
}
.img-bg-soft:before,
.img-bg-softer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.light-bg.img-bg-soft:before {
  background-color: #F2F5F7;
  opacity: .66;
}
.dark-bg.img-bg-soft:before {
  background-color: #2F4052;
  opacity: .57;
}
.tint-bg.img-bg-soft:before {
  background-color: $green;
  opacity: .84;
}
.light-bg.img-bg-softer:before {
  background-color: #F2F5F7;
  opacity: .95;
}
.dark-bg.img-bg-softer:before {
  background-color: #2F4052;
  opacity: .97;
}
.tint-bg.img-bg-softer:before {
  background-color: $green;
  opacity: .96;
}
.screen-container {
  position: relative;
  width: 100%;
  height: auto !important;
  background: url("../images/art/screen-container.png") no-repeat top / 100%;
  overflow: hidden;
}
.screen-container:before{
  content: "";
  display: block;
  padding-top: 47%;
}
.screen-container .tab-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5% 4.5%;
  padding-bottom: 0;
}
.sidelines {
  position: relative;
  line-height: 100%;
  border-bottom: 1px solid #CED7E0;
  margin-top: -.35em;
  margin-bottom: 1.5em;
}
.sidelines span {
  position: relative;
  top: .5em;
  background: #FFF;
  padding: 0 .5em 0 0;
}
.sidelines.text-right span,
.text-right .sidelines span {
  padding: 0 0 0 .5em;
}
.sidelines.text-center span,
.text-center .sidelines span {
  padding: 0 .5em;
}
.team-headline.sidelines {
  margin-top: -20px;
  margin-bottom: 40px;
}
.light-bg .sidelines {
  border-color: #BCC7D1;
}
.light-bg .sidelines span {
  background: #F2F5F7;
}
.dark-bg .sidelines {
  border-color: #73879C;
}
.dark-bg .sidelines span {
  background: #2F4052;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe + iframe {
  margin-top: 10px;
}
.isotope, .owl-carousel .owl-wrapper-outer {
  z-index: 0;
}
.green-bg {
  background-color: $green !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.blue-bg {
  background-color: #3F8DBF !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.red-bg {
  background-color: #FA6C65 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.orange-bg {
  background-color: #F27A24 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.purple-bg {
  background-color: #9B59B6 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.pink-bg {
  background-color: #D487BE !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.navy-bg {
  background-color: #34495E !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.gray-bg {
  background-color: #95A5A6 !important;
  color: #FFF !important;
  padding: 2px 8px 4px;
  margin-top: -2px;
}
.light-bg {
  background-color: #F2F5F7;
}
.dark-bg {
  background-color: #2F4052;
}
.dark-bg,
.dark-bg p,
.dark-bg .item-details li:before {
  color: #A3B1BF;
}
.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4 {
  color: #FFF;
}
.dark-bg .widget h1,
.dark-bg .widget h2,
.dark-bg .widget h3,
.dark-bg .widget h4 {
  color: #FFF;
}
.tint-bg {
  background-color: $green;
  color: #FFF;
  padding: 2px 8px;
  margin-top: -2px;
}
.tint-bg,
.tint-bg h1,
.tint-bg h2,
.tint-bg h3,
.tint-bg h4,
.tint-bg p,
.tint-bg .item-details li:before,
.tint-bg .icon i.icn {
  color: #FFF;
}
.tint-bg a:hover {
  color: #506A85;
}
.light-color,
.light-color .dark-bg {
  color: #FFF !important;
}
.medium-color {
  color: #A3B1BF !important;
}
.dark-color {
  color: #506A85 !important;
}
.tint-color {
  color: $green !important;
}
.green {
  color: $green !important;
}
.blue {
  color: #3F8DBF !important;
}
.red {
  color: #FA6C65 !important;
}
.pink {
  color: #D487BE !important;
}
.purple {
  color: #9B59B6 !important;
}
.orange {
  color: #F27A24 !important;
}
.navy {
  color: #34495E !important;
}
.gray {
  color: #95A5A6 !important;
}
.icon {
  display: inline-block;
  margin-bottom: 20px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.icon.circle {
  padding: 30px;
  border: 1px solid $green;
  border-radius: 50%;
}
.icon.circle.xs {
  padding: 15px;
}
.icon.circle.sm {
  padding: 20px;
}
.icon.circle.lg {
  padding: 35px;
}
.icon i.icn {
  display: block;
  color: $green;
  font-size: 65px;
  line-height: 100%;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.icon i.icn.xs {
  font-size: 20px;
}
.icon i.icn.sm {
  font-size: 35px;
}
.icon i.icn.lg {
  font-size: 85px;
}
i.contact {
  margin-right: 5px;
  width: auto;
  height: auto;
  line-height: 18px;
}
.tagcloud {
  line-height: 25px;
}
footer {
  font-size: 14px;
  line-height: 22px;
}
footer.dark-bg {
  color: #A3B1BF;
}
footer .inner {
  padding-top: 45px;
  padding-bottom: 35px;
}
footer .container > .row {
  margin-left: -25px;
  margin-right: -25px;
}
footer .row .inner {
  padding: 25px;
}
footer .logo {
  height: auto;
  max-height: 100%;
}
footer h4 {
  text-transform: uppercase;
  margin-bottom: 20px;
}
footer p {
  margin-bottom: 15px;
}
.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-menu li {
  display: inline;
  padding-left: 10px;
}
.footer-menu li:before {
  display: inline-block;
  content: "·";
  padding-right: 12px;
}
.footer-menu li:first-child:before {
  display: none;
}
.footer-bottom {
  background: #283645;
  color: #707E8C;
}
.footer-bottom .container.inner {
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer-bottom p,
.footer-bottom a {
  color: #707E8C;
}
.footer-bottom p {
  padding: 0;
  margin: 0;
}
.meta,
.more {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  cursor: default;
}
.meta span:before {
  display: inline-block;
  //content: "|";
  padding: 0 10px 0 7px;
}
.meta span:first-child:before {
  display: none;
  padding: 0;
}
.meta a {
  display: inline !important;
}
.meta a:hover,
.more:hover {
  color: $green;
}
.meta.tags a:before {
  content: "#";
  display: inline-block;
}
.bordered .info {
  text-align: center;
}
.bordered .info h3,
.bordered .info h4 {
  margin-bottom: 5px;
}
.bordered .info h3 a,
.bordered .info h4 a {
  color: #506A85;
}
.bordered .info h3 a:hover,
.bordered .info h4 a:hover {
  color: $green;
}
.bordered .info p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}
.bordered {
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  padding: 25px;
  background: #F5F7FA;
}
.light-bg .bordered {
  background: #FFF;
}
.dark-bg .bordered .info h3,
.dark-bg .bordered .info h4,
.tint-bg .bordered .info h3,
.tint-bg .bordered .info h4 {
  color: #506A85;
}
.dark-bg .bordered .info p,
.tint-bg .bordered .info p {
  color: #73879C;
}
.no-top-border {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.border {
  border: 1px solid #CED7E0;
}
.border-top-lg,
.border-top-md,
.border-top-sm,
.border-top-xs,
.border-top {
  border-top: 1px solid #CED7E0;
}
.border-right-lg,
.border-right-md,
.border-right-sm,
.border-right-xs,
.border-right {
  border-right: 1px solid #CED7E0;
}
.border-bottom-lg,
.border-bottom-md,
.border-bottom-sm,
.border-bottom-xs,
.border-bottom {
  border-bottom: 1px solid #CED7E0;
}
.border-left-lg,
.border-left-md,
.border-left-sm,
.border-left-xs,
.border-left {
  border-left: 1px solid #CED7E0;
}
.border-lg,
.border-top-lg,
.border-right-lg,
.border-bottom-lg,
.border-left-lg {
  border-width: 20px;
}
.border-md,
.border-top-md,
.border-right-md,
.border-bottom-md,
.border-left-md {
  border-width: 10px;
}
.border-sm,
.border-top-sm,
.border-right-sm,
.border-bottom-sm,
.border-left-sm {
  border-width: 5px;
}
.border-xs,
.border-top-xs,
.border-right-xs,
.border-bottom-xs,
.border-left-xs {
  border-width: 3px;
}
.light-bg .border,
.light-bg .border-lg,
.light-bg .border-md,
.light-bg .border-sm,
.light-bg .border-xs,
.light-bg .border-top-lg,
.light-bg .border-top-md,
.light-bg .border-top-sm,
.light-bg .border-top-xs,
.light-bg .border-top,
.light-bg .border-right-lg,
.light-bg .border-right-md,
.light-bg .border-right-sm,
.light-bg .border-right-xs,
.light-bg .border-right,
.light-bg .border-bottom-lg,
.light-bg .border-bottom-md,
.light-bg .border-bottom-sm,
.light-bg .border-bottom-xs,
.light-bg .border-bottom,
.light-bg .border-left-lg,
.light-bg .border-left-md,
.light-bg .border-left-sm,
.light-bg .border-left-xs,
.light-bg .border-left {
  border-color: #BCC7D1;
}
.dark-bg .border,
.dark-bg .border-lg,
.dark-bg .border-md,
.dark-bg .border-sm,
.dark-bg .border-xs,
.dark-bg .border-top-lg,
.dark-bg .border-top-md,
.dark-bg .border-top-sm,
.dark-bg .border-top-xs,
.dark-bg .border-top,
.dark-bg .border-right-lg,
.dark-bg .border-right-md,
.dark-bg .border-right-sm,
.dark-bg .border-right-xs,
.dark-bg .border-right,
.dark-bg .border-bottom-lg,
.dark-bg .border-bottom-md,
.dark-bg .border-bottom-sm,
.dark-bg .border-bottom-xs,
.dark-bg .border-bottom,
.dark-bg .border-left-lg,
.dark-bg .border-left-md,
.dark-bg .border-left-sm,
.dark-bg .border-left-xs,
.dark-bg .border-left {
  border-color: #73879C;
}
.tint-bg .border,
.tint-bg .border-lg,
.tint-bg .border-md,
.tint-bg .border-sm,
.tint-bg .border-xs,
.tint-bg .border-top-lg,
.tint-bg .border-top-md,
.tint-bg .border-top-sm,
.tint-bg .border-top-xs,
.tint-bg .border-top,
.tint-bg .border-right-lg,
.tint-bg .border-right-md,
.tint-bg .border-right-sm,
.tint-bg .border-right-xs,
.tint-bg .border-right,
.tint-bg .border-bottom-lg,
.tint-bg .border-bottom-md,
.tint-bg .border-bottom-sm,
.tint-bg .border-bottom-xs,
.tint-bg .border-bottom,
.tint-bg .border-left-lg,
.tint-bg .border-left-md,
.tint-bg .border-left-sm,
.tint-bg .border-left-xs,
.tint-bg .border-left {
  border-color: #FFF;
}
.last-bottom {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.last-right {
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.reset-margin {
  margin: 0;
}
.reset-margin-left {
  margin-left: 0;
}
.reset-margin-right {
  margin-right: 0;
}
.reset-padding {
  padding: 0;
}
.reset-padding-left {
  padding-left: 0;
}
.reset-padding-right {
  padding-right: 0;
}
.single-block {
  padding: 0;
  border: 0;
  margin: 0;
}
h1 + .single-line,
h2 + .single-line,
h3 + .single-line,
h4 + .single-line {
  margin-top: -.5em;
}
.img-intext {
  margin-bottom: 20px;
}
aside {
  font-size: 16px;
  line-height: 160%;
}
aside p {
  margin-bottom: 15px;
}
aside .txt-btn {
  font-size: 100%;
}
aside .thumb figure {
  margin: 0;
}
.thumbs.no-gap {
  margin-left: 0;
  margin-right: 0;
}
.thumbs.no-gap .thumb {
  padding: 0;
}
.thumbs.gap-xs {
  margin-left: -5px;
  margin-right: 0;
}
.thumbs.gap-xs .thumb {
  padding: 0 0 5px 5px;
}
.thumbs.gap-sm {
  margin-left: -10px;
  margin-right: 0;
}
.thumbs.gap-sm .thumb {
  padding: 0 0 10px 10px;
}
.thumbs.gap-md {
  margin-left: -20px;
  margin-right: 0;
}
.thumbs.gap-md .thumb {
  padding: 0 0 20px 20px;
}
.thumbs.gap-lg {
  margin-left: -30px;
  margin-right: 0;
}
.thumbs.gap-lg .thumb {
  padding: 0 0 30px 30px;
}


/*===================================================================================*/
/*	HEADER
/*===================================================================================*/

.navbar .container {
  display: table;
  padding: 0;
}
.navbar {
  margin: 0;
  border-radius: 0;
  border: none;
  position: relative;
  z-index: 1000;
  min-height: inherit;
}
.navbar ul {
  list-style: none;
}
.navbar-brand {
  float: none;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  max-width: 300px;
}
.navbar-brand img {
  display: inline-block;
  height: 40px;
}
.navbar-brand img.animate {
  -moz-transition: none;
}
.navbar-header .navbar-brand {
  display: none;
}
.navbar-header {
  float: none !important;
  position: relative;
  background: #F5F7FA;
  border-bottom: 1px solid #E6E9ED;
  z-index: 0;
}
.navbar-header li,
.navbar-header a {
  display: table-cell;
  vertical-align: middle;
  height: 40px;
}
.navbar-collapse {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E6E9ED;
}
.navbar-collapse.collapse.animate {
  -moz-transition: none;
}
.navbar-collapse.collapse.affix {
  position: fixed;
  top: 0;
}
.navbar-collapse.collapse.animate-after {
  background-color: rgba(255, 255, 255, 0.97);
}
.navbar-nav {
  float: none;
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  margin: 0;
}
.navbar-nav > li > a {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #506A85;
  padding: 15px 20px;
  border: 1px solid rgba(255,255,255,0);
  border-radius: 3px;
  bottom: -1px;
  z-index: 2;
}
.navbar-nav > li.dropdown > a {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.125s ease-out;
}
.animate-after .navbar-nav > li > a {
  border-radius: 0;
  margin-top: -1px;
}
.nav > li.active > a {
  color: $green;
}
.nav > li > a:focus {
  background-color: transparent;
  border-color: transparent;
}
.nav > li > a:hover {
  background-color: #F5F7FA;
  border-color: #E6E9ED;
  color: $green;
}
.navbar .dropdown-menu {
  padding: 0;
  margin: 0;
  width: 210px;
  background: #F5F7FA;
  border: none;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-bottom-color: #CED2D6;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  -webkit-border-top-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  border-top-left-radius: 0 !important;
  -webkit-box-shadow: 0 10px 10px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 10px 10px rgba(0,0,0,0.15);
  box-shadow: 0 10px 10px rgba(0,0,0,0.15);
  z-index: 0;
}
.navbar .dropdown.open .dropdown-menu {
  z-index: 1;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li {
  background: transparent;
  border: none;
  border-top: 1px solid #E6E9ED;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li:first-child {
  border: none;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a {
  line-height: 18px;
  padding: 13px 20px;
  color: #73879C;
  text-transform: uppercase;
  font-weight: 700;
  white-space: normal;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) li a.active {
  filter: none;
  background-color: #F5F7FA;
  color: $green;
}
.navbar .nav .open > a,
.navbar .nav .open > a:hover,
.navbar .nav .open > a:focus {
  background-color: #F5F7FA;
  border-color: #E6E9ED;
}
.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):after {
  display: none;
}
.navbar .nav > li > .dropdown-menu:not(.yamm-dropdown-menu):before {
  display: none;
}
.navbar .dropdown-submenu .dropdown-menu {
  top: -1px !important;
}
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > li > a:focus,
.navbar .dropdown-submenu:hover > a,
.navbar .dropdown-submenu:focus > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:hover,
.navbar .dropdown-menu:not(.yamm-dropdown-menu) > .active > a:focus {
  background: $green;
  color: #FFF !important;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-header .info {
  padding-left: 0;
  margin: 0;
}
.navbar-header .info li {
  font-size: 12px;
  padding-right: 25px;
}
.navbar-header .info li a {
  font-size: 12px;
  color: #73879C;
}
.navbar-header .info a:hover {
  color: $green;
}
.navbar-form {
  padding: 0;
  margin: 0;
}
.btn.responsive-menu {
  display: none;
  width: 40px;
  height: 40px;
  font-size: 15px;
  padding: 0;
  margin: 0;
}


/*===================================================================================*/
/*	MEGA MENU
/*===================================================================================*/

.yamm .nav,
.yamm .dropup,
.yamm .dropdown,
.yamm .collapse {
  position: static;
}
.yamm .navbar-inner,
.yamm .container {
  position: relative;
}
.yamm .dropdown-menu {
  top: auto;
  left: auto;
  -webkit-border-top-left-radius: 3px !important;
  -moz-border-radius-topleft: 3px !important;
  border-top-left-radius: 3px !important;
}
.yamm .dropdown-menu > li {
  display: block;
}
.yamm .nav.pull-right .dropdown-menu {
  right: 0;
}
.yamm .yamm-content {
  font-size: 14px;
  line-height: 22px;
  padding: 30px 40px;
  margin-left: -20px;
  margin-right: -20px;
}
.yamm .yamm-content .inner {
  padding: 0 20px;
}
.yamm .yamm-content li {
  line-height: inherit;
}
.yamm .yamm-content:before,
.yamm .yamm-content:after {
  display: table;
  content: "";
  line-height: 0;
}
.yamm .yamm-content:after {
  clear: both;
}
.yamm .nav > li > .dropdown-menu:after,
.yamm .nav > li > .dropdown-menu:before {
  display: none;
}
.yamm .yamm-content img {
  width: 100%;
}
.yamm .yamm-content a {
  color: inherit;
}
.yamm .yamm-content h4 {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.yamm .yamm-content p {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 15px;
}
.yamm .yamm-content .btn {
  margin: 10px 0;
}
.yamm .yamm-content a:hover {
  color: $green;
  opacity: 1;
}
.yamm .dropdown.yamm-fullwidth .dropdown-menu {
  width: 100%;
  left: 0;
  right: 0;
}


/*===================================================================================*/
/*	BUTTONS
/*===================================================================================*/

#buttons .btn {
  margin: 5px 3px;
}
#buttons .btn-wrapper {
  margin: 30px 0;
}
.btn {
  color: #FFF !important;
  background: $green;
  padding: 11px 20px 13px;
  margin: 15px 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  border: none;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
}
.btn-small {
  padding: 5px 13px 6px;
  font-size: 13px;
}
.btn-large {
  padding: 14px 26px 16px;
  font-size: 15px;
}
.btn.btn-green {
  background: $green;
}
.btn.btn-blue {
  background: #3F8DBF;
}
.btn.btn-red {
  background: #FA6C65;
}
.btn.btn-pink {
  background: #D487BE;
}
.btn.btn-purple {
  background: #9B59B6;
}
.btn.btn-orange {
  background: #F27A24;
}
.btn.btn-navy {
  background: #34495E;
}
.btn.btn-gray {
  background: #95A5A6;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  background: #17A78B;
  color: #FFF !important;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
  background: #17A78B !important;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
  background: #387EAA !important;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active {
  background: #CF4C45 !important;
}
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active {
  background: #BD78A9 !important;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active {
  background: #8A4FA2 !important;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active {
  background: #D96716 !important;
}
.btn-navy:hover,
.btn-navy:focus,
.btn-navy:active,
.btn-navy.active {
  background: #2C3E50 !important;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active,
.btn-gray.active {
  background: #859394 !important;
}
.tint-bg .btn {
  background: #FFF !important;
  color: $green !important;
}
.tint-bg .btn:hover {
  background: #E9E9E9 !important;
  color: #506A85 !important;
}
.btn.share-facebook {
  background: #3d5b9b !important
}
.btn.share-facebook:hover {
  background: #334c8e !important
}
.btn.share-twitter {
  background: #5aa8cd !important
}
.btn.share-twitter:hover {
  background: #499ac8 !important
}
.btn.share-googleplus {
  background: #3b3b3b !important
}
.btn.share-googleplus:hover {
  background: #2e2e2e !important
}
.btn.share-pinterest {
  background: #c53942 !important
}
.btn.share-pinterest:hover {
  background: #bc2d32 !important
}
.btn-share-lg,
.btn-share-md,
.btn-share-sm,
.btn-share-xs {
  display: table;
  position: relative;
  z-index: 0;
  width: 100%;
  text-align: center;
}
.btn-share-lg i,
.btn-share-md i,
.btn-share-sm i,
.btn-share-xs i {
  display: table-cell;
  vertical-align: middle;
}
.btn-share-lg {
  height: 250px;
}
.btn-share-lg i {
  font-size: 80px;
}
.btn-share-md {
  height: 180px;
}
.btn-share-md i {
  font-size: 60px;
}
.btn-share-sm {
  height: 120px;
}
.btn-share-sm i {
  font-size: 40px;
}
.btn-share-xs {
  height: 80px;
}
.btn-share-xs i {
  font-size: 28px;
}
.btn-share-lg i:after,
.btn-share-md i:after,
.btn-share-sm i:after,
.btn-share-xs i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
/*.btn-share-lg:hover i:after,
.btn-share-md:hover i:after,
.btn-share-sm:hover i:after,
.btn-share-xs:hover i:after {
	height: 100%;
}*/
.btn-share-lg:hover .icon-s-facebook:after,
.btn-share-md:hover .icon-s-facebook:after,
.btn-share-sm:hover .icon-s-facebook:after,
.btn-share-xs:hover .icon-s-facebook:after {
  background: #3B5998;
  background: #4668B3;
}
.btn-share-lg:hover .icon-s-gplus:after,
.btn-share-md:hover .icon-s-gplus:after,
.btn-share-sm:hover .icon-s-gplus:after,
.btn-share-xs:hover .icon-s-gplus:after {
  background: #DD4B39;
  background: #DE5745;
}
.btn-share-lg:hover .icon-s-twitter:after,
.btn-share-md:hover .icon-s-twitter:after,
.btn-share-sm:hover .icon-s-twitter:after,
.btn-share-xs:hover .icon-s-twitter:after {
  background: #00ACED;
  background: #2FBBED;
}
.btn-share-lg p,
.btn-share-md p,
.btn-share-sm p,
.btn-share-xs p {
  position: absolute;
  height: 1.1em;
  font-family: 'Lato', sans-serif;
  line-height: 100%;
  color: #FFF;
  margin: auto;
  opacity: 0;
}
.btn-share-lg p,
.btn-share-md p {
  top: 20px;
}
.btn-share-sm p,
.btn-share-xs p {
  top: 0;
  bottom: 0;
}
.btn-share-sm p {
  font-size: 16px;
}
.btn-share-xs p {
  font-size: 15px;
}
.btn-share-lg:hover p,
.btn-share-md:hover p,
.btn-share-sm:hover p,
.btn-share-xs:hover p {
  opacity: 1;
}
.btn-share-lg .name,
.btn-share-md .name,
.btn-share-sm .name,
.btn-share-xs .name {
  left: 25px;
}
.btn-share-lg .counter,
.btn-share-md .counter,
.btn-share-sm .counter,
.btn-share-xs .counter {
  right: 25px;
}
.btn-share-lg:hover,
.btn-share-md:hover,
.btn-share-sm:hover,
.btn-share-xs:hover {
  color: #FFF;
}
h1 .btn,
h2 .btn,
h3 .btn,
h4 .btn,
p .btn {
  margin-left: 15px;
}
.txt-btn {
  font-size: 105%;
  font-weight: 700;
}
.txt-btn:after {
  font-family: 'fontello';
  font-size: 75%;
  content: '\e846';
  margin-left: 7px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.txt-btn:hover:after {
  margin-left: 12px;
}


/*===================================================================================*/
/*	SOCIAL ICONS
/*===================================================================================*/

.social {
  padding: 0;
  margin: 0;
}
.social li {
  font-family: 'fontello-social';
  margin-right: 4px;
}
.social li {
  position: relative;
}
.text-center .social li {
  margin: 0 2px;
}
.social li a {
  display: table;
  position: relative;
}
.social li a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #73879C;
  width: 30px;
  height: 30px;
  font-size: 12px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.social li a i:after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.social li a:hover i {
  color: #FFF;
}
.social li a:hover i:after {
  height: 100%;
}
.social a .icon-s-facebook:after {
  background: #3B5998;
  background: #4668B3;
}
.social a .icon-s-gplus:after {
  background: #DD4B39;
  background: #DE5745;
}
.social a .icon-s-twitter:after {
  background: #00ACED;
  background: #2FBBED;
}
.social a .icon-s-pinterest:after {
  background: #CB2027;
  background: #D94348;
}
.social a .icon-s-behance:after {
  background: #1769FF;
  background: #3079FF;
}
.social a .icon-s-dribbble:after {
  background: #EA4C89;
  background: #EB6397;
}


/*===================================================================================*/
/*	TABS ON SIDE
/*===================================================================================*/

.tabs-side .etabs {
  float: left;
  width: 20%;
  padding: 0;
  margin: 0 -1px 0 0;
}
.tabs-side .tab {
  display: block;
  position: relative;
  z-index: 0;
}
.tabs-side .tab a {
  display: block;
  background: #F5F7FA;
  color: #506A85;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  border: 1px solid #E6E9ED;
  border-right-color: #CED7E0;
  border-bottom: none;
  overflow: hidden;
}
.tabs-side .tab:first-child a {
  border-top-left-radius: 3px;
}
.tabs-side .tab:last-child a {
  border-bottom: 1px solid #E6E9ED;
  border-bottom-left-radius: 3px;
}
.tabs-side .tab a:hover {
  background: $green;
  color: #FFF;
  border-color: $green;
}
.tabs-side .tab:hover,
.tabs-side .tab.active {
  z-index: 2;
}
.tabs-side .tab.active a {
  background: #FFF !important;
  color: $green;
  border-color: #CED7E0 !important;
  border-right-color: #FFF !important;
}
.tabs-side .tab.active + .tab a {
  border-top-color: #CED7E0;
}
.tabs-side .tab.active + .tab a:hover {
  border-top-color: $green;
}
.tabs-side .tab a div {
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px;
}
.tabs-side.tab-container .panel-container {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-left: 70px;
  border-left: 1px solid #CED7E0;
}
.tabs-side.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS ON TOP
/*===================================================================================*/

.tabs-top .etabs {
  padding: 0;
  margin: 0;
}
.tabs-top .etabs:after {
  content: '';
  display: block;
  position: relative;
  z-index: 1;
  border-top: 1px solid #CED7E0;
  margin-top: -1px;
}
.tabs-top .tab {
  display: inline-block;
  position: relative;
  z-index: 0;
  max-width: 40%;
  margin: 0 3px;
}
.tabs-top .tab a {
  display: inline-block;
  vertical-align: bottom;
  background: #F5F7FA;
  color: #506A85;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  border: 1px solid #E6E9ED;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}
.tabs-top .tab a div {
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px;
}
.tabs-top .tab a:hover {
  background: $green;
  color: #FFF;
  border-color: $green;
}
.tabs-top .tab.active {
  z-index: 2;
}
.tabs-top .tab.active a {
  background: #FFF !important;
  color: $green;
  border-color: #CED7E0 !important;
}
.tabs-top.tab-container .panel-container {
  margin-top: 70px;
}
.tabs-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS CIRCLED ON TOP
/*===================================================================================*/

.tabs-circle-top .etabs {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}
.tabs-circle-top .etabs:before {
  content: '';
  display: block;
  border-bottom: 1px solid #CED7E0;
  margin: 65px 0 -65px;
}
.tabs-circle-top .tab {
  display: inline-block;
  height: 160px;
  position: relative;
  top: 0;
  margin: 0 15px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.tabs-circle-top .tab a {
  display: table-cell;
  vertical-align: middle;
  width: 130px;
  height: 130px;
  max-width: 130px;
  background: #FFF;
  color: #506A85;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px;
  border: 1px solid #CED7E0;
  border-radius: 50%;
  overflow: hidden;
}
.tabs-circle-top .tab a div {
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 200;
  margin: -10px 0 5px;
}
.tabs-circle-top .tab a:hover {
  /*border: 4px solid $green;*/
  background-color: #F5F7FA;
  border-color: #CED7E0 !important;
}
.tabs-circle-top .tab.active {
  top: -15px;
}
.tabs-circle-top .tab.active a {
  height: 160px;
  width: 160px;
  max-width: 160px;
  background: $green;
  font-size: 15px;
  line-height: 140%;
  color: #FFF;
  border: 1px solid $green;
}
.tabs-circle-top .tab.active a:hover {
  border-color: transparent !important;
}
.tabs-circle-top .tab.active a div {
  font-size: 50px;
  margin: -13px 0 8px;
}
.tabs-circle-top.tab-container .panel-container {
  position: relative;
  margin-top: 30px;
  z-index: 1;
}
.tabs-circle-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px;
}


/*===================================================================================*/
/*	TABS 2-BIG ON TOP
/*===================================================================================*/

.tabs-2-big-top .etabs {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
}
.tabs-2-big-top .tab {
  position: relative;
  display: inline-block;
  width: 40%;
  border-bottom: 2px solid transparent;
  border-radius: 3px;
  margin: 0 15px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.tabs-2-big-top .tab:before, .tabs-2-big-top .tab:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.tabs-2-big-top .tab:before {
  border-width: 23px;
  margin-left: -23px;
}
.tabs-2-big-top .tab:after {
  border-width: 20px;
  margin-left: -20px;
}
.tabs-2-big-top .tab a {
  display: block;
  padding: 35px;
  color: #506A85;
  opacity: .5;
}
.tabs-2-big-top .tab:hover {
  background: #F7F9FA;
  border-color: #E6EBF0;
}
.tabs-2-big-top .tab:hover:before {
  border-top-color: #E6EBF0;
}
.tabs-2-big-top .tab:hover:after {
  border-top-color: #F7F9FA;
}
.tabs-2-big-top .tab.active {
  background: #FFF;
  border-color: #E1E7ED;
}
.tabs-2-big-top .tab.active:before {
  border-top-color: #E1E7ED;
}
.tabs-2-big-top .tab.active:after {
  border-top-color: #FFF;
}
.tabs-2-big-top .tab:hover a,
.tabs-2-big-top .tab.active a {
  opacity: 1;
}
.tabs-2-big-top .tab a p {
  margin-bottom: 0;
}
.tabs-2-big-top.tab-container .panel-container {
  position: relative;
  margin-top: 80px;
  z-index: 1;
}
.tabs-2-big-top.tab-container .panel-container p {
  margin: 0;
  padding-bottom: 20px;
}


/*===================================================================================*/
/*	TESTIMONIALS / QUOTES
/*===================================================================================*/

.format-quote {
  position: relative;
}
.format-quote:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../images/art/pattern-background01.jpg");
  opacity: .05;
}
#testimonials #owl-testimonials {
  margin-top: 10px;
}
blockquote {
  position: relative;
}
#testimonials #owl-testimonials:before,
blockquote:before {
  display: block;
  position: relative;
  font-family: 'fontello';
  content: '\275e';
  font-size: 40px;
  line-height: 100%;
}
#testimonials #owl-testimonials:before {
  top: -10px;
  opacity: .3;
}
#testimonials #owl-testimonials blockquote:before {
  display: none;
}
blockquote:before {
  position: absolute;
  right: 0;
  bottom: 0;
}
.post.format-quote {
  background-color: $green;
}
.format-quote .meta,
.format-quote blockquote,
.format-quote blockquote footer,
.format-quote blockquote small,
.format-quote blockquote .small {
  color: #FFF;
}
.format-quote blockquote footer:before,
.format-quote blockquote small:before,
.format-quote blockquote .small:before,
.format-quote blockquote footer:after,
.format-quote blockquote small:after,
.format-quote blockquote .small:after {
  background-color: #FFF;
}
.format-quote .meta a:hover {
  color: #506A85;
}


/*===================================================================================*/
/*	ACCORDION / TOGGLE
/*===================================================================================*/

.panel-group {
  margin-bottom: 0;
}
.panel-group + .panel-group {
  margin-top: 20px;
}
.panel-group .panel {
  background: #F5F7FA;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  box-shadow: none;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: #E6E9ED;
}
.panel-body {
  padding: 25px;
}
.panel-heading {
  background-color: inherit !important;
  color: inherit !important;
  padding: 0;
  border-color: inherit !important;
}
.panel-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  line-height: 1.42857;
  font-weight: 700;
  text-transform: uppercase;
}
.panel-title > a {
  display: block;
  padding: 15px 25px 15px;
}
.panel-title > a.collapsed {
  background: #F5F7FA;
  color: #506A85;
}
.panel-title > a,
.panel-title > a:hover {
  background: #EDF0F2;
}
.panel-title > a {
  color: #506A85;
}
.panel-title > a:hover {
  color: $green;
}
.panel-title > a span {
  display: block;
  position: relative;
  top: 0;
  color: #506A85;
  padding-right: 25px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.text-right .panel-title > a span {
  padding-left: 25px;
  padding-right: 0;
}
.text-center .panel-title > a span {
  padding-left: 0;
  padding-right: 0;
}
.panel-title > a:hover span {
  color: inherit;
}
.text-center .panel-title > a:hover span {
  top: .5em;
  color: transparent;
}
.panel-title > a span:after {
  font-family: "fontello";
  content: "\e849";
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.text-right .panel-title > a span:after {
  left: 0;
  right: auto;
}
.text-center .panel-title > a span:after {
  top: -.75em;
  left: 0;
  opacity: 0;
}
.panel-title > a.collapsed span:after {
  content: "\e84c";
}
.text-center .panel-title > a:hover span:after {
  color: $green;
  top: -.5em;
  opacity: 1;
}
.panel-group .bordered {
  background: #FFF;
}

.light-bg .panel-group .panel {
  background: #FFF;
}
.light-bg .panel-title > a.collapsed,
.light-bg .blank .panel-title > a.collapsed {
  background: #F8FAFD;
}
.light-bg .panel-title > a,
.light-bg .blank .panel-title > a,
.light-bg .panel-title > a:hover,
.light-bg .blank .panel-title > a:hover {
  /*background: #E6E9ED;*/
  background: #FFF;
}

.dark-bg .panel-group .panel,
.dark-bg .panel-group .panel-heading + .panel-collapse .panel-body {
  border-color: #485867;
}
.dark-bg .panel-group .panel {
  background: #3A4A59;
}
.dark-bg .panel-title > a.collapsed {
  background: #566473;
}
.dark-bg .panel-title > a,
.dark-bg .panel-title > a:hover {
  /*background: #E6E9ED;*/
  background: #485867;
}
.dark-bg .panel-title > a,
.dark-bg .panel-title > a span {
  color: #FFF;
}
.dark-bg .blank .panel-title > a.collapsed {
  background: #2F4052;
}
.dark-bg .blank .panel-title > a.collapsed,
.dark-bg .blank .panel-title > a {
  border-color: #566473;
}
.dark-bg .blank .panel-title > a,
.dark-bg .blank .panel-title > a:hover {
  background: #485867;
}

.blank .panel-title > a.collapsed {
  background: #FFF;
}
.panel-group.blank .panel {
  background: none;
}
.panel-group.blank .panel {
  border: none;
}
.blank .panel-title > a.collapsed,
.blank .panel-title > a {
  border: 1px solid #E6E9ED;
}
.blank .panel-title > a,
.blank .panel-title > a:hover {
  background: #F5F7FA;
}
.blank .panel-heading,
.blank .panel-title > a {
  border-radius: 3px;
}
.panel-group.blank .panel-heading + .panel-collapse .panel-body {
  border: none;
}
.blank .panel-body {
  padding: 35px 0;
}
.blank.panel-group .bordered,
.light-bg .panel-group .bordered,
.dark-bg .panel-group .bordered {
  background: #F5F7FA;
}
.light-bg .blank.panel-group .bordered {
  background: #FFF;
}


/*===================================================================================*/
/*	PORTFOLIO
/*===================================================================================*/

.items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.items:before,
.items:after {
  display: table;
  content: "";
  line-height: 0;
}
.items:after {
  clear: both
}
.items li {
  float: left;
  min-height: 1px;
  position: relative;
}
.items.col-3 {
  margin-left: -8px;
  margin-bottom: -8px;
}
.items.col-3 li {
  width: 318px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.items.col-3.gap {
  margin-left: -20px;
  margin-bottom: -20px;
}
.items.col-3.gap li {
  width: 310px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.items.col-4 {
  margin-left: -10px;
  margin-bottom: -10px;
}
.items.col-4 li {
  width: 235px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.items.col-4 .post-title {
  font-size: 16px;
  margin-bottom: 3px;
}
.items.fullscreen {
  width: 100%;
  clear: both;
  margin: 0 0 -1px 0;
  padding: 0;
  list-style: none;
}
.items.fullscreen li {
  float: left;
  position: relative;
  background: none;
  padding: 0;
  width: 16.666666666%;
}
.items.fullscreen li img {
  display: block;
  /*position: relative;*/
  width: 100%;
  height: auto;
}
.items.fullscreen:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.items li img {
  display: block;
  width: 100%;
}
.items li,
.items li img,
.thumbs .thumb figure,
.owl-carousel .item figure {
  display: block;
  position: relative;
}
.items li {
  overflow: hidden;
}
.items li .text-overlay,
.thumbs .thumb .text-overlay,
.owl-carousel .item .text-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 10%;
  font: 0px/0 a; /* remove the gap between inline(-block) elements */
  background-color: rgba(26,187,156, 0.9);
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0;
}
.items li .text-overlay:before,
.thumbs .thumb .text-overlay:before,
.owl-carousel .item .text-overlay:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.items li:hover .text-overlay,
.thumbs .thumb:hover .text-overlay,
.owl-carousel .item:hover .text-overlay {
  opacity: 1;
}
.thumbs .thumb:hover .member .text-overlay {
  opacity: 0;
}
.thumbs .thumb .member .member-image:hover .text-overlay {
  opacity: 1;
}
.items li .text-overlay .info,
.thumbs .thumb .text-overlay .info,
.owl-carousel .item .text-overlay .info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 22px;
  /*color: #FFF;*/
  text-align: center;
}
.items li .text-overlay .info h2,
.thumbs .thumb .text-overlay .info h2,
.owl-carousel .item .text-overlay .info h2,
.items li .text-overlay .info h3,
.thumbs .thumb .text-overlay .info h3,
.owl-carousel .item .text-overlay .info h3,
.items li .text-overlay .info h4,
.thumbs .thumb .text-overlay .info h4,
.owl-carousel .item .text-overlay .info h4 {
  color: #FFF;
  margin-bottom: 2px;
}
.items li .text-overlay .info p,
.thumbs .thumb .text-overlay .info p,
.owl-carousel .item .text-overlay .info p {
  font-size: 14px;
  line-height: 22px;
  color: #FFF;
  margin-bottom: 0;
}
.items li .text-overlay .info.big p,
.thumbs .thumb .text-overlay .info.big p,
.owl-carousel .item .text-overlay .info.big p,
#hero .items li .text-overlay .info.big p,
#hero .thumbs .thumb .text-overlay .info.big p,
#hero .owl-carousel .item .text-overlay .info.big p {
  font-size: 18px;
  line-height: 140%;
  padding-top: 10px;
  margin-top: 0;
}

#owl-clients .item .text-overlay,
#clients .thumb .text-overlay {
  background: none;
}

#owl-clients .item img,
#clients .thumb img {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

#owl-clients .item:hover img,
#clients .thumb:hover img {
  opacity: .1;
}

#owl-clients .item .text-overlay .info h4,
#owl-clients .item .text-overlay .info p,
#clients .thumb .text-overlay .info h4,
#clients .thumb .text-overlay .info p {
  color: inherit;
}
.filter,
.format-filter {
  padding: 0;
  margin-bottom: 40px;
}
.filter:before,
.format-filter:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  border-bottom: 1px solid #CED7E0;
}
.filter li,
.format-filter li {
  display: inline;
  position: relative;
  background: #FFF;
  font-size: 15px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: .031em;
  text-transform: uppercase;
  margin: 0 -2px;
}
.light-bg .format-filter li {
  background: #F2F5F7;
}
.filter li:after,
.format-filter li:after {
  content: "\00b7";
}
.filter li:last-child:after,
.format-filter li:last-child:after {
  content: "";
}
.filter li a,
.format-filter li a {
  padding: 0 15px;
}
.light-bg .filter:before {
  border-color: #BCC7D1;
}
.light-bg .filter li {
  background: #F2F5F7;
}
.dark-bg .filter:before {
  border-color: #73879C;
}
.dark-bg .filter li {
  background: #2F4052;
}
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 600ms;
  -moz-transition-duration: 600ms;
  -ms-transition-duration: 600ms;
  -o-transition-duration: 600ms;
  transition-duration: 600ms;
}
.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, left, opacity;
  transition-property: transform, opacity;
}
.isotope figure,
.items figure {
  margin: 0;
}
.post-content .isotope figure {
  margin: auto;
}


/*===================================================================================*/
/*	OVERLAY
/*===================================================================================*/

.icon-overlay {
  display: block;
  position: relative;
}
.icon-overlay img {
  display: block;
  max-width: 100%;
}
.icon-overlay a .icn-more {
  opacity: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-decoration: none;
  width: 100%;
  z-index: 100;
  background-color: rgba(26,187,156, 0.9);
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  overflow: hidden;
}
.icon-overlay a:hover .icn-more {
  opacity: 1;
}
.icon-overlay a .icn-more:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  position: absolute;
  text-align: center;
  content: '\2b';
  width: 60px;
  height: 60px;
  font-size: 60px;
  line-height: 1;
  color: #FFF;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin: -30px 0 0 -30px;
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.icon-overlay a:hover .icn-more:before {
  font-size: 30px;
  padding-top: 15px;
}
.icon-overlay.icn-link a .icn-more:before {
  content: '\2b';
}


/*===================================================================================*/
/*	PORTFOLIO POST
/*===================================================================================*/

.item-details {
  list-style: none;
  font-size: 16px;
  line-height: 140%;
  padding-left: 0;
  margin-bottom: 0;
}
.item-details li + li {
  margin-top: 6px;
}
.item-details li:before {
  content: "";
  font-family: "fontello";
  color: #506A85;
  margin-right: 10px;
}
.item-details li.date:before {
  content: "\e82c";
}
.item-details li.categories:before {
  content: "\e70d";
}
.item-details li.client:before {
  content: "\f464";
}
.item-details li.url:before {
  content: "\f30e";
}
.text-center .item-details li {
  display: inline-block;
  margin: 0 15px 6px;
}


/*===================================================================================*/
/*	OWL CAROUSEL
/*===================================================================================*/

.owl-carousel {
  text-align: center;
  cursor: default;
}
.owl-controls {
  display: inline-block;
  position: relative;
  margin-top: 40px;
}
.panel-group .panel .owl-controls {
  margin-top: 25px;
}
.panel-group.blank .panel .owl-controls {
  margin-top: 40px;
}
.owl-pagination {
  position: relative;
  line-height: 30px;
}
.owl-buttons {
  display: none;
}
.owl-prev,
.owl-next {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #FFF;
  background-color: #506A85;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: auto;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0;
}
.owl-prev {
  left: -35px;
}
.owl-next {
  right: -35px;
}
.owl-carousel:hover .owl-prev {
  left: -40px;
  opacity: .25;
}
.owl-carousel:hover .owl-next {
  right: -40px;
  opacity: .25;
}
.owl-carousel:hover .owl-prev:hover,
.owl-carousel:hover .owl-next:hover {
  color: $green;
  opacity: 1;
}
.owl-outer-nav:after {
  content: "";
  position: absolute;
  top: 0;
  left: -15%;
  width: 130%;
  height: 100%;
  z-index: 0;
}
.owl-outer-nav .owl-wrapper-outer,
.owl-outer-nav .owl-pagination,
.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next {
  z-index: 1;
}
.owl-outer-nav .owl-controls {
  position: static;
}
.owl-outer-nav .owl-prev {
  left: -65px;
}
.owl-outer-nav .owl-next {
  right: -65px;
}
.owl-outer-nav:hover .owl-prev {
  left: -80px;
}
.owl-outer-nav:hover .owl-next {
  right: -80px;
}


.owl-ui-md .owl-pagination {
  line-height: 45px;
}
.owl-ui-md .owl-prev,
.owl-ui-md .owl-next {
  width: 45px;
  height: 45px;
  font-size: 45px;
}
.owl-ui-md .owl-prev {
  left: -55px;
}
.owl-ui-md .owl-next {
  right: -55px;
}
.owl-ui-md:hover .owl-prev {
  left: -60px;
}
.owl-ui-md:hover .owl-next {
  right: -60px;
}

.owl-outer-nav.owl-ui-md:after {
  left: -18%;
  width: 136%;
}
.owl-outer-nav.owl-ui-md .owl-prev {
  left: -85px;
}
.owl-outer-nav.owl-ui-md .owl-next {
  right: -85px;
}
.owl-outer-nav.owl-ui-md:hover .owl-prev {
  left: -100px;
}
.owl-outer-nav.owl-ui-md:hover .owl-next {
  right: -100px;
}

.owl-ui-lg .owl-pagination {
  line-height: 60px;
}
.owl-ui-lg .owl-prev,
.owl-ui-lg .owl-next {
  width: 60px;
  height: 60px;
  font-size: 60px;
}
.owl-ui-lg .owl-prev {
  left: -75px;
}
.owl-ui-lg .owl-next {
  right: -75px;
}
.owl-ui-lg:hover .owl-prev {
  left: -80px;
}
.owl-ui-lg:hover .owl-next {
  right: -80px;
}

.owl-outer-nav.owl-ui-lg:after {
  left: -22%;
  width: 144%;
}
.owl-outer-nav.owl-ui-lg .owl-prev {
  left: -105px;
}
.owl-outer-nav.owl-ui-lg .owl-next {
  right: -105px;
}
.owl-outer-nav.owl-ui-lg:hover .owl-prev {
  left: -120px;
}
.owl-outer-nav.owl-ui-lg:hover .owl-next {
  right: -120px;
}

.owl-inner-nav .owl-controls {
  position: static;
}
.owl-inner-nav .owl-prev {
  left: 45px;
}
.owl-inner-nav .owl-next {
  right: 45px;
}
.owl-inner-nav:hover .owl-prev {
  left: 30px;
}
.owl-inner-nav:hover .owl-next {
  right: 30px;
}

.owl-outer-nav .owl-prev,
.owl-outer-nav .owl-next,
.owl-inner-nav .owl-prev,
.owl-inner-nav .owl-next {
  bottom: 70px;
}

.owl-outer-nav.owl-ui-md .owl-prev,
.owl-outer-nav.owl-ui-md .owl-next,
.owl-inner-nav.owl-ui-md .owl-prev,
.owl-inner-nav.owl-ui-md .owl-next {
  bottom: 85px;
}

.owl-outer-nav.owl-ui-lg .owl-prev,
.owl-outer-nav.owl-ui-lg .owl-next,
.owl-inner-nav.owl-ui-lg .owl-prev,
.owl-inner-nav.owl-ui-lg .owl-next {
  bottom: 100px;
}

.owl-inner-pagination .owl-pagination,
.owl-inner-pagination .owl-prev,
.owl-inner-pagination .owl-next {
  margin-top: -40px;
  top: -60px;
}

.owl-inner-pagination.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-ui-md .owl-prev,
.owl-inner-pagination.owl-ui-md .owl-next {
  margin-top: -50px;
  top: -65px;
}

.owl-inner-pagination.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-ui-lg .owl-prev,
.owl-inner-pagination.owl-ui-lg .owl-next {
  margin-top: -60px;
  top: -75px;
}

.owl-inner-pagination.owl-outer-nav .owl-prev,
.owl-inner-pagination.owl-outer-nav .owl-next,
.owl-inner-pagination.owl-inner-nav .owl-prev,
.owl-inner-pagination.owl-inner-nav .owl-next {
  margin: auto;
  top: 0;
  bottom: 43px;
}

.owl-inner-pagination .owl-pagination {
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0;
}

.owl-inner-pagination:hover .owl-pagination {
  opacity: 1;
}

.owl-inner-pagination.owl-inner-nav .owl-pagination,
.owl-inner-pagination.owl-outer-nav .owl-pagination {
  top: -45px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-md .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md .owl-pagination {
  top: -50px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-lg .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg .owl-pagination {
  top: -60px;
}

.owl-inner-pagination.owl-inner-nav:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav:hover .owl-pagination {
  top: -60px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-md:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-md:hover .owl-pagination {
  top: -65px;
}

.owl-inner-pagination.owl-inner-nav.owl-ui-lg:hover .owl-pagination,
.owl-inner-pagination.owl-outer-nav.owl-ui-lg:hover .owl-pagination {
  top: -75px;
}

#owl-main .item {
  height: 44vh;
  background-color: #FFF;
  //background-position: center 55%;
  //background-position: top center;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#owl-main.height-md .item {
  height: 69vh;
}
#owl-main.height-lg .item {
  height: 90vh;
}
#owl-main .container {
  display: table;
  height: inherit;
}
#owl-main .caption {
  display: table-cell;
}
#owl-main .caption.vertical-center {
  vertical-align: middle;
  padding-bottom: 3vh;
}
#owl-main .caption.vertical-top {
  vertical-align: top;
  padding-top: 8vh;
}
#owl-main .caption.vertical-bottom {
  vertical-align: bottom;
  padding-bottom: 14vh;
}
#owl-main .caption.text-center {
  padding-left: 10%;
  padding-right: 10%;
}
#owl-main .caption.text-left {
  padding-right: 20%;
}
#owl-main .caption.text-right {
  padding-left: 20%;
}
#owl-main .caption h1,
#owl-main .caption p,
#owl-main .caption div {
  position: relative;
}
#owl-main .caption h1 span {
  padding: .250em 0;
  line-height: 180%;
}
#owl-main .caption .light-bg,
#owl-main .caption .medium-bg,
#owl-main .caption .dark-bg,
#owl-main .caption .tint-bg {
  background: none;
  padding-left: .500em;
  padding-right: .500em;
}
#owl-main .caption h1.light-bg span,
#owl-main .caption p.light-bg span {
  background: #FFF;
  box-shadow: -.500em 0 0 #FFF, .500em 0 0 #FFF;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
#owl-main .caption h1.medium-bg span,
#owl-main .caption p.medium-bg span {
  background: #A3B1BF;
  box-shadow: -.500em 0 0 #A3B1BF, .500em 0 0 #A3B1BF;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
#owl-main .caption h1.dark-bg span,
#owl-main .caption p.dark-bg span {
  background: #506A85;
  box-shadow: -.500em 0 0 #506A85, .500em 0 0 #506A85;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
#owl-main .caption h1.tint-bg span,
#owl-main .caption p.tint-bg span {
  background: $green;
  box-shadow: -.500em 0 0 $green, .500em 0 0 $green;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
#owl-main .owl-controls {
  display: block;
  position: static;
  margin-top: -47px;
}
#owl-main .owl-pagination {
  background: #FFF;
  line-height: inherit;
  position: relative;
  bottom: -40px;
  padding: 10px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: auto;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
#owl-main:hover .owl-pagination {
  bottom: -25px;
  opacity: 1;
}
#owl-main .owl-prev,
#owl-main .owl-next {
  bottom: 0;
}

.owl-controls .owl-page {
  display: inline-block;
}
.owl-pagination .owl-page span {
  display: block;
  width: 15px;
  height: 15px;
  background: #506A85;
  border: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.owl-pagination .owl-page.active span,
.owl-pagination .owl-page:hover span {
  background: $green;
}
.owl-item-gap .item {
  margin: 0 15px;
}
.owl-item-gap-sm .item {
  margin: 0 10px;
}
/* preloading images */
.owl-item.loading {
  min-height: inherit;
  background: none;
}


/*===================================================================================*/
/*	PRICING TABLES
/*===================================================================================*/

.pricing .plan {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
}
.pricing .plan header {
  background: #2F4052;
  text-align: center;
  padding: 50px;
}
.pricing .plan h2 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: .15em;
  text-transform: uppercase;
  color: #FFF;
}
.pricing .plan .btn {
  margin-bottom: 0;
}
.pricing .plan .price {
  margin-bottom: 15px;
}
.pricing .plan .price * {
  font-family: 'Lato', sans-serif;
  line-height: 100%;
}
.pricing .plan .price .amount {
  font-size: 120px;
  font-weight: 900;
  color: #FFF;
}
.pricing .plan .price .currency {
  position: relative;
  top: 16px;
  font-size: 24px;
  vertical-align: top;
  margin-right: 5px;
}
.pricing .plan .price .period {
  font-size: 16px;
  text-transform: uppercase;
}
.pricing .plan .features {
  background: #F5F7FA;
  padding: 25px 35px;
  border: 1px solid #E6E9ED;
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: 0 !important;
}
.pricing .plan .features li {
  padding: 15px 0;
  /*border-top: 1px solid #CED7E0;*/
  border-top: 1px solid #BCC7D1;
}
.pricing .plan .features li:first-child {
  border: none;
}
.pricing .plan .features li i {
  margin-right: 10px;
}
.pricing.col-4 .plan header {
  padding: 40px;
}
.pricing.col-4 .plan h2 {
  font-size: 18px;
}
.pricing.col-4 .plan .price .amount {
  font-size: 90px;
}
.pricing.col-4 .plan .features {
  padding: 20px 30px;
}


/*===================================================================================*/
/*	FORMS
/*===================================================================================*/

.forms {
  position: relative;
  padding: 0;
  width: 100%;
}
.form-container .response {
  display: none;
}
.forms ol {
  margin: 0;
  padding: 0;
}
.forms ol li {
  line-height: auto;
  list-style: none;
}
input,
textarea,
.uneditable-input,
.form-inline .form-control,
.navbar-form .form-control {
  width: 100%;
}
select,
textarea,
textarea.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
  color: #73879C;
  resize: none;
  vertical-align: middle;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #f5f5f5;
  border: 1px solid #E6E9ED;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
}
textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border: 1px solid #BCC7D1;
  box-shadow: none;
}
textarea {
  height: 100%;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 150px;
  resize: vertical;
}
textarea.form-control {
  padding: 10px;
}
.btn-submit {
  width: auto;
}
.forms .btn-submit {
  margin-bottom: 0;
}
.forms .error,
.forms .error:focus {
  border: 1px solid #d99898;
}
.forms label {
  display: block;
  float: left;
  width: 95px;
  padding-top: 7px;
  font-size: 13px;
  clear: both;
}
.forms input,
.forms textarea {
  background: #F8FAFD;
  border-radius: 3px;
}
.light-bg .forms input,
.light-bg .forms textarea {
  background: #FFF;
}
.form-container:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.newsletter {
  position: relative;
}
.newsletter input[type="email"] {
  background: rgba(255,255,255,0.15);
  padding-right: 100px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  color: #FFF !important;
}
.newsletter input[type="email"]:focus {
  background: rgba(255,255,255,0.20);
}
.navbar .searchbox .dropdown-menu {
  width: 270px;
}
.search {
  position: relative;
  margin: 15px;
  margin-bottom: 0;
}
.search input[type="search"] {
  background: #FFF;
  padding-right: 40px;
}
.newsletter .btn-submit,
.search .btn-submit {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin-top: 5px;
  margin-right: 5px;
  color: #FFF !important;
  line-height: 1;
}
.navbar-form.search .btn-submit {
  width: 30px;
  height: 30px;
}
footer .newsletter .btn-submit {
  width: 88px;
  height: 30px;
  font-size: 13px;
}
#response ul.errors {
  margin-bottom: 25px;
}
#response .btn i {
  font-size: 12px;
}
.dark-bg input,
.dark-bg button,
.dark-bg select,
.dark-bg textarea {
  color: #A3B1BF;
}
.form-control::-webkit-input-placeholder {
  color: #C2C7CC;
}
.form-control:-moz-placeholder {
  color: #C2C7CC;
}
.form-control::-moz-placeholder {
  color: #C2C7CC;
}
.form-control:-ms-input-placeholder {
  color: #C2C7CC;
}
.dark-bg .form-control::-webkit-input-placeholder {
  color: #828E99;
}
.dark-bg .form-control:-moz-placeholder {
  color: #828E99;
}
.dark-bg .form-control::-moz-placeholder {
  color: #828E99;
}
.dark-bg .form-control:-ms-input-placeholder {
  color: #828E99;
}
.search .form-control::-webkit-input-placeholder {
  color: #C2C7CC;
}
.search .form-control:-moz-placeholder {
  color: #C2C7CC;
}
.search .form-control::-moz-placeholder {
  color: #C2C7CC;
}
.search .form-control:-ms-input-placeholder {
  color: #C2C7CC;
}
input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-ms-input-placeholder {
  color: transparent !important;
}


/*===================================================================================*/
/*	BLOG
/*===================================================================================*/

.blog.no-sidebar .post,
.blog.no-sidebar #comments,
.blog.no-sidebar .pagination,
.blog.no-sidebar .comment-form-wrapper {
  width: 770px;
  margin: 0px auto !important;
}
.blog.no-sidebar .post-title,
.blog.no-sidebar .meta {
  text-align: center
}
.format-filter li {
  font-size: 24px;
}
.format-filter li:after {
  content: "";
}
.format-filter li a {
  padding: 0 35px;
}
.classic-blog .meta {
  margin-right: -5px;
  margin-left: -5px;
}
.classic-blog .post-content .meta {
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 25px;
}
.meta li {
  display: inline-block;
  margin: 0 6px;
}
.meta li.categories:before,
.meta li a:before {
  content: "";
  font-family: "fontello";
  margin-right: 6px;
}
.meta li.categories a:before,
.meta li.categories:before {
  margin-right: 0;
}
/*.meta li.categories:before {
	content: "\e70d";
	-webkit-transition: all 200ms ease-out;
	   -moz-transition: all 200ms ease-out;
		 -o-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
}*/
.meta li.categories:hover:before {
  color: $green;
  cursor: pointer;
}
.meta li.url a:before {
  content: "\f30e";
}
.meta li.author-posts a:before {
  content: "\27a6";
}
.meta li.comments a:before {
  content: "\e718";
}
.meta li.comments a:after {
  content: "Comments";
  margin-left: 4px;
}
.meta li.likes a:before {
  content: "\2665";
}
.meta li.likes a:after {
  content: "Likes";
  margin-left: 4px;
}
.meta li:after {
  /*content: "\0399";*/
  //content: "|";
  margin-left: 12px;
}
.meta li:last-child:after {
  content: "";
}
.classic-blog .sidemeta,
.classic-blog .pagination {
  padding-left: 59px;
  border-left: 1px solid #CED7E0;
  margin-left: 30px;
}
.classic-blog .post .date-wrapper {
  text-align: center;
  display: table;
  float: left;
  margin-right: 20px;
}
.classic-blog .post .date {
  display: table-cell;
  vertical-align: middle;
  line-height: 1;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border: 1px solid $green;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: $green;
  font-weight: bold;
  color: #FFF;
  line-height: 1;
  text-transform: uppercase;
}
.classic-blog .post .date .day {
  font-size: 20px;
  margin-bottom: 1px;
  display: block;
}
.classic-blog .post .date .month {
  font-size: 12px;
  display: block;
}
.classic-blog .sidemeta .format-wrapper {
  display: table;
}
.classic-blog.sidebar-left .sidemeta,
.classic-blog.sidebar-left .pagination {
  padding-left: 0;
  border-left: none;
  margin-left: 0;
  padding-right: 59px;
  border-right: 1px solid #CED7E0;
  margin-right: 30px;
}
.classic-blog.sidebar-left .post .date-wrapper {
  margin-right: 0;
  margin-left: 20px;
}
.classic-blog.no-sidebar .sidemeta,
.classic-blog.no-sidebar .pagination {
  position: relative;
  left: -30px;
}
#blog-post .classic-blog .post {
  width: 100%;
}
.classic-blog .post {
  width: 750px;
}
.classic-blog .post-title {
  font-size: 40px;
  line-height: 120%;
}
.classic-blog .isotope {
  overflow: visible !important;
}
.classic-blog #share {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 40px;
}
.classic-blog .panel-group {
  margin-bottom: 40px;
}
.post {
  position: relative;
  background: #FFF;
  padding-bottom: 60px;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  margin-bottom: 40px;
}
.sidemeta .format-wrapper a {
  display: table-cell;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background: #FFF;
  text-align: center;
  color: $green;
  line-height: 1;
  border: 1px solid $green;
  border-radius: 50%;
}
.sidemeta .format-wrapper a:hover {
  background: $green;
  color: #FFF;
}
.post-content > * {
  margin-left: 80px;
  margin-right: 80px;
}
.post-content *:last-child {
  margin-bottom: 0;
}
.post-content > .post-media {
  margin-left: 0;
  margin-right: 0;
}
.post-content > *:first-child,
.post-media + * {
  margin-top: 60px;
}
.post-content > .post-media:first-child {
  margin-top: 0;
}
.owl-carousel.post-media + * {
  margin-top: 20px;
}
.post-media iframe {
  margin-bottom: -7px;
}
.sidemeta .date-wrapper {
  position: absolute;
  top: 0;
  left: -90px;
}
.sidemeta .format-wrapper {
  position: absolute;
  top: 75px;
  left: -80px;
  font-size: 15px;
}
.classic-blog.sidebar-left .sidemeta .date-wrapper {
  left: auto;
  right: -90px;
}
.classic-blog.sidebar-left .sidemeta .format-wrapper {
  left: auto;
  right: -80px;
}
.grid-blog .post figure {
  margin-bottom: -1px;
}
.grid-blog .post .date {
  display: inline;
}
.grid-blog:before,
.grid-blog:after {
  display: table;
  content: "";
  line-height: 0;
}
.grid-blog:after {
  clear: both;
}
.grid-blog .post {
  float: left;
  min-height: 1px;
  position: relative;
}
.grid-blog.col-2 .post,
.grid-blog.col-3.no-sidebar .post {
  padding-bottom: 40px;
}
.grid-blog.col-2 .post-content > *,
.grid-blog.col-3.no-sidebar .post-content > * {
  margin-left: 40px;
  margin-right: 40px;
}
.grid-blog.col-2 .post-content > .post-media,
.grid-blog.col-3.no-sidebar .post-content > .post-media {
  margin-left: 0;
  margin-right: 0;
}
.grid-blog.col-2 .post-content > *:first-child,
.grid-blog.col-2 .post-media + *,
.grid-blog.col-3.no-sidebar .post-content > *:first-child,
.grid-blog.col-3.no-sidebar .post-media + * {
  margin-top: 40px;
}
.grid-blog.col-2 .post-content > .post-media:first-child,
.grid-blog.col-2 .owl-carousel.post-media + *,
.grid-blog.col-3.no-sidebar .post-content > .post-media:first-child,
.grid-blog.col-3.no-sidebar .owl-carousel.post-media + * {
  margin-top: 0;
}
.grid-blog.col-2 .post-title,
.grid-blog.col-3.no-sidebar .post-title {
  font-size: 30px;
}
.grid-blog.col-2 .post-content .meta,
.grid-blog.col-3.no-sidebar .post-content .meta {
  padding-left: 40px;
  padding-right: 40px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 25px;
}
.grid-blog.col-2.no-sidebar .post {
  padding-bottom: 50px;
}
.grid-blog.col-2.no-sidebar .post-content > * {
  margin-left: 50px;
  margin-right: 50px;
}
.grid-blog.col-2.no-sidebar .post-content > .post-media {
  margin-left: 0;
  margin-right: 0;
}
.grid-blog.col-2.no-sidebar .post-content > *:first-child,
.grid-blog.col-2.no-sidebar .post-media + * {
  margin-top: 45px;
}
.grid-blog.col-2.no-sidebar .post-content > .post-media:first-child,
.grid-blog.col-2.no-sidebar .owl-carousel.post-media + * {
  margin-top: 0;
}
.grid-blog.col-2.no-sidebar .post-title {
  font-size: 33px;
}
.grid-blog.col-2.no-sidebar .post-content .meta {
  padding-left: 50px;
  padding-right: 50px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 25px;
}
.post.format-link {
  background: #2F4052;
  color: #A3B1BF;
}
.post.format-link .post-title {
  color: #FFF;
}


/*===================================================================================*/
/*	AUTHOR
/*===================================================================================*/

.author {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 160%;
  color: #506A85;
  margin-bottom: 5px;
}
.author a {
  border-bottom: none;
}
.post-author,
#comments {
  background: #FFF;
  padding: 40px;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  border-radius: 3px;
  margin-bottom: 40px;
}
.post-author .author-image {
  float: left;
}
.post-author .author-image img {
  width: 120px;
}
.post-author .author-details {
  position: relative;
  font-size: inherit;
  padding-top: 0;
  padding-left: 30px;
  z-index: 0;
  overflow: hidden;
}
.post-author .author-details p a {
  font-weight: 700;
  border-bottom: none;
}
.post-author .author-details .social li {
  display: table-cell;
}
.post-author .author-details .social li a i {
  width: 33px;
  height: 45px;
  font-size: 15px;
}
.post-author .author-details .meta {
  padding-left: 0;
  margin-top: 12px;
}
.post-author .author-details .social,
.post-author .author-details .meta {
  float: left;
  display: inline-block;
}


/*===================================================================================*/
/*	SIDEBAR
/*===================================================================================*/

.sidebar {
  padding-left: 25px !important
}
.sidebar.left-sidebar {
  padding: 15px !important
}
.sidebox + .sidebox {
  margin-top: 60px;
}
.sidebox h3 {
  font-size: 16px;
  margin-bottom: 15px;
}
.sidebox.widget form,
.sidebox.widget input {
  margin: 0;
}
.sidebox.widget input {
  border-radius: 3px;
}
.widget .list {
  list-style: none;
  margin: 0;
}
.sidenav {
  background: #F5F7FA;
  padding: 0;
  border: 1px solid #E6E9ED;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 0;
}
.sidenav li {
  border-bottom: 1px solid #E6E9ED;
}
.sidenav li a {
  display: block;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #506A85;
  padding: 13px 18px;
}
.sidenav li a:hover {
  background: $green;
  color: #FFF;
}
.sidenav li a:after {
  position: absolute;
  right: 18px;
  content: "\e762";
  font-family: 'fontello';
  font-size: 18px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  opacity: 0;
}
.sidenav li a:hover:after {
  opacity: 1;
}


/*===================================================================================*/
/*	TOOLTIPS
/*===================================================================================*/

.tooltip-inner {
  color: #fff;
  background-color: $green;
  padding: 5px 12px;
}
.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
  border-top-color: $green;
}
.tooltip.right .tooltip-arrow {
  border-right-color: $green;
}
.tooltip.left .tooltip-arrow {
  border-left-color: $green;
}
.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: $green;
}


/*===================================================================================*/
/*	PAGINATION
/*===================================================================================*/

.pagination {
  display: block;
  border-radius: 0;
  margin: 0;
}
.pagination > li {
  display: inline-block;
  margin-left: 10px;
}
.pagination > li:first-child {
  margin-left: 0;
}
.pagination > li > a,
.pagination > li > span {
  float: none;
  display: inline-block;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  background: $green;
  padding: 11px 20px 13px;
  margin-left: 0;
  font-weight: 700;
  font-size: 14px;
  text-shadow: none;
  text-transform: uppercase;
  border: none;
  -webkit-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
  box-shadow: inset 0 -2px 0 rgba(0,0,0,0.15);
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > li > a:hover,
.pagination > li > a:focus {
  background: #17A78B;
  color: #FFF;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a {
  border-radius: 3px;
}


/*===================================================================================*/
/*	COMMENTS
/*===================================================================================*/

#comments {
  position: relative;
}
#comments .commentlist {
  list-style: none;
  margin: 0;
  padding: 0;
}
#comments .commentlist > .comment {
  padding-top: 40px;
  border-top: 1px solid #E6E9ED;
  margin-top: 40px;
}
#comments .commentlist > .comment:first-child {
  border-top: none;
  padding-top: 0;
}
#comments .comment *:last-child {
  margin-bottom: 0;
}
#comments .commentlist .children {
  padding-left: 80px;
}
#comments .commentlist .children .comment {
  padding-top: 40px;
}
#comments .avatar {
  float: left;
}
#comments .avatar span {
  border-radius: 50%;
}
#comments .avatar img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
#comments .commentbody {
  padding-left: 22px;
  overflow: hidden;
}
#comments .author {
  margin-bottom: 15px;
}
#comments .author h3 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
}
#comments .author .meta {
  display: inline-block;
  /*opacity: .5;*/
  color: #BCC7D1;
  margin: 0;
}
#comments .message .meta {
  text-align: right;
  /*opacity: .5;*/
  color: #BCC7D1;
  padding-left: 0;
}
#comments .message .meta li {
  padding-top: 0;
  border-top: none;
  margin-top: 0;
}
#comments .message .meta li.reply {
  float: left;
}
#comments .message .meta li.reply a:before {
  font-family: 'fontello';
  content: "\e712";
  margin-right: 6px;
}
#comments .message .meta li a:before,
#comments .message .meta li:after {
  content: "";
  margin-left: 0;
  margin-right: 0;
}
.comment-form-wrapper p {
  padding-bottom: 20px
}
.blog.no-sidebar #comments .meta {
  text-align: left
}


/*===================================================================================*/
/*	GO TO TOP / SCROLL UP
/*===================================================================================*/

#scrollUp {
  bottom: 20px;
  right: 20px;
  font-size: 36px;
  line-height: 100%;
  color: #FFF;
  background-color: #506A85;
  padding: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  opacity: .5;
}
#scrollUp:hover {
  color: $green;
  opacity: 1;
}


/*===================================================================================*/
/*	FEATURES / STYLES
/*===================================================================================*/

.font-icons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.font-icons li {
  display: inline-block;
  font-size: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.alert .close {
  line-height: 28px;
}
.retina-icons-code i {
  font-size: 18px;
}
.retina-icons-code tr td:first-child,
.retina-icons-code tr th:first-child {
  text-align: center;
}
.retina-icons-code code {
  color: #73879C;
  background: none;
}
.table-bordered {
  border: 1px solid #E6E9ED;
  border-left: none;
  background: #FFF;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #E6E9ED;
}
.table th,
.table td {
  border-top: 1px solid #E6E9ED;
}
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #F5F7FA;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border-color: #E6E9ED;
}


/*===================================================================================*/
/*	MODALS
/*===================================================================================*/

.modal {
  z-index: 9000;
  background: none;
}
.modal.fade {
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.modal.fade .modal-dialog {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
}
.modal.fade.in .modal-dialog {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
/*.modal.fade .modal-dialog {
	-webkit-transform: translate(0);
	    -ms-transform: translate(0);
	        transform: translate(0);
	-webkit-transition: none;
	        transition: none;
}*/
.modal-open .modal {
  overflow: hidden;
}
.modal-backdrop {
  background-color: #F2F5F7;
  z-index: 8999;
}
.modal-backdrop.in {
  opacity: .95;
}
.modal .container {
  width: 100%;
  max-width: 1170px;
  padding-left: 50px;
  padding-right: 50px;
}
.modal-dialog {
  position: absolute;
  top: 35px;
  bottom: 35px;
  left: 35px;
  right: 35px;
  width: calc(100% - 70px);
  margin: auto;
}
.modal-full {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.modal-lg {
  max-width: 100%;
  max-height: 100%;
}
.modal-md {
  max-width: 1170px;
  max-height: 800px;
}
.modal-sm {
  max-width: 750px;
  max-height: 500px;
}
.modal-xs {
  max-width: 480px;
  max-height: 350px;
}
.modal-content {
  width: 100%; /* Can use left: 0; right: 0; with position: absolute; as well */
  height: 100%; /* Can use top: 0; bottom: 0; with position: absolute; as well */
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  border-bottom: 2px solid #CED2D6;
  border-radius: 3px;
  -webkit-box-shadow: 0 30px 30px rgba(0,0,0,0.1);
  box-shadow: 0 30px 30px rgba(0,0,0,0.1);
  overflow: hidden;
}
.modal-full .modal-content {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-header {
  position: relative;
  z-index: 1;
  height: 70px;
  padding: 22px 25px;
  background-color: #FFF;
  border-bottom: 1px solid #E6E9ED;
}
.modal-header .close {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #506A85;
  text-shadow: none;
  margin-top: 0;
  margin-left: 25px;
  -webkit-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
}
.dark-bg.no-modal-header .modal-header .close,
.tint-bg.no-modal-header .modal-header .close {
  color: #FFF;
  opacity: .4;
}
.modal-header .close:hover,
.modal-header .close:focus {
  opacity: 1 !important;
}
.modal-title {
  color: #506A85 !important;
  line-height: 150%;
}
.no-modal-header .modal-header {
  background-color: transparent;
  padding-right: 40px;
  border-bottom: none;
}
.no-modal-header .modal-header .modal-title {
  display: none;
}
.no-modal-footer .modal-footer {
  display: none;
}
.no-modal-header .modal-body {
  top: 0;
}
.no-modal-footer .modal-body {
  bottom: 0;
}
.modal-body {
  position: absolute;
  top: 70px;
  bottom: 75px;
  width: 100%; /* Can use left: 0; right: 0; as well */
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%; /* Can use left: 0; right: 0; as well */
  height: 75px;
  background-color: #FFF;
  border-top: 1px solid #E6E9ED;
}
.modal-footer .btn {
  margin: 0;
}
.modal-open #scrollUp {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 0;
}
.btn-modal {
  background: #F5F7FA !important;
  color: #506A85 !important;
  border: 1px solid #E6E9ED;
  border-bottom-width: 2px;
  box-shadow: none !important;
}
.btn-modal:hover,
.btn-modal:focus,
.btn-modal:active,
.btn-modal.active {
  background: #EDF0F2 !important;
  color: $green !important;
}


/*===================================================================================*/
/*	RESPONSIVE
/*===================================================================================*/

@media (min-width: 1200px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  .owl-buttons {
    display: block;
  }
  .items.col-4 {
    margin-left: -10px;
    margin-bottom: -10px;
  }
  .items.col-4 li {
    width: 285px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .items.col-3 {
    margin-left: -9px;
    margin-bottom: -9px;
  }
  .items.col-3 li {
    width: 384px;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  .items.col-3.gap {
    margin-left: -21px;
    margin-bottom: -21px;
  }
  .items.col-3.gap li {
    width: 376px;
    margin-left: 21px;
    margin-bottom: 21px;
  }
  .items.col-4.gap {
    margin-left: -18px;
    margin-bottom: -18px;
  }
  .items.col-4.gap li {
    width: 279px;
    margin-left: 18px;
    margin-bottom: 18px;
  }
  .grid-blog.col-2 {
    margin-left: -40px;
  }
  .sidebar-left .grid-blog.col-2 {
    margin-left: -20px;
  }
  .sidebar-left .pagination {
    margin-left: 20px;
  }
  .grid-blog.col-2 .post {
    width: 390px;
    margin-left: 40px;
    margin-bottom: 40px;
  }
  .grid-blog.col-2.no-sidebar .post {
    width: 565px;
  }
  .grid-blog.col-3 {
    margin-left: -30px;
  }
  .grid-blog.col-3 .post {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  .grid-blog.col-3.no-sidebar .post {
    width: 370px;
  }
  .sidebar {
    padding-left: 35px !important;
  }
  .modal-dialog {
    top: 50px;
    bottom: 50px;
    left: 50px;
    right: 50px;
    width: calc(100% - 100px);
  }
}

@media (min-width: 1025px) {
  .navbar-collapse.collapse {
    padding-bottom: 30px;
  }
  .navbar .dropdown-submenu > a:after {
    display: none;
  }
  .navbar .dropdown-submenu > .dropdown-menu:not(.yamm-dropdown-menu) {
    margin-top: 0;
    margin-left: -10px;
  }
  .dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu>.dropdown-menu:not(.yamm-dropdown-menu) {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }
  .dropdown-submenu:hover>.dropdown-menu:not(.yamm-dropdown-menu) {
    display: block;
  }
  .dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
  }
  .dropdown-submenu.pull-left {
    float: none;
  }
  .dropdown-submenu.pull-left>.dropdown-menu:not(.yamm-dropdown-menu) {
    left: -100%;
    margin-left: 10px;
  }
  .navbar-nav .dropdown .dropdown-menu {
    visibility: hidden;
    display: block;
    transition: all 0.125s ease-out;
    opacity: 0;
    margin-top: -10px;
  }
  .navbar-nav .dropdown.open > .dropdown-menu,
  .navbar-nav .dropdown.open .dropdown-submenu:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    margin-left: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {

  /*	br {
		display: none;
	}*/

  #team .inner-left {
    padding-left: 40px;
  }

  #team .inner-right {
    padding-right: 40px;
  }

  .classic-blog .post {
    width: 601px;
    padding-bottom: 50px;
  }
  .classic-blog .post-content > * {
    margin-left: 50px;
    margin-right: 50px;
  }
  .classic-blog .post-content > .post-media {
    margin-left: 0;
    margin-right: 0;
  }
  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + * {
    margin-top: 45px;
  }
  .classic-blog .post-content > .post-media:first-child,
  .classic-blog .owl-carousel.post-media + * {
    margin-top: 0;
  }
  .classic-blog .post-title {
    font-size: 33px;
  }
  .classic-blog .post-content .meta {
    padding-left: 50px;
    padding-right: 50px;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 25px;
  }
  .grid-blog.col-2 {
    margin-left: -30px;
  }
  .grid-blog.col-2 .post {
    width: 330px;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  .grid-blog.col-2 .post-title {
    font-size: 28px;
  }
  .grid-blog.col-2.no-sidebar {
    margin-left: -40px;
  }
  .grid-blog.col-2.no-sidebar .post {
    width: 465px;
    margin-left: 40px;
    margin-bottom: 40px;
  }
  .grid-blog.col-3.no-sidebar {
    margin-left: -25px;
  }
  .grid-blog.col-3.no-sidebar .post {
    width: 306px;
    margin-left: 25px;
    margin-bottom: 25px;
  }
  .grid-blog.col-3.no-sidebar .post-title {
    font-size: 24px;
  }
  .grid-blog.col-3.no-sidebar p {
    font-size: 16px;
  }
  .tabs-circle-top .etabs:before {
    margin: 55px 0 -55px;
  }
  .tabs-circle-top .tab {
    height: 140px;
    margin: 0 10px;
  }
  .tabs-circle-top .tab a {
    width: 110px;
    height: 110px;
    max-width: 110px;
    font-size: 12px;
  }
  .tabs-circle-top .tab a div {
    font-size: 36px;
  }
  .tabs-circle-top .tab.active a {
    height: 140px;
    width: 140px;
    max-width: 140px;
    font-size: 14px;
  }
  .tabs-circle-top .tab.active a div {
    font-size: 46px;
  }
}

@media (max-width: 1024px) {

  html {
    overflow-x: hidden;
  }

  #hero .info,
  #hero .info,
  #hero .info,
  .items li .text-overlay .info.big,
  .thumbs .thumb .text-overlay .info.big,
  .owl-carousel .item .text-overlay .info.big {
    margin-top: -35px;
  }

  .retina-icons-code code {
    font-size: 11.5px;
  }

  .tabs-side.tab-container .panel-container {
    padding-left: 50px;
  }

  br {
    display: none;
  }

  #team .inner-left {
    padding-left: 30px;
  }

  #team .inner-right {
    padding-right: 30px;
  }

  .classic-blog .post {
    width: 630px;
    padding-bottom: 50px;
  }
  .classic-blog .post-content > * {
    margin-left: 50px;
    margin-right: 50px;
  }
  .classic-blog .post-content > .post-media {
    margin-left: 0;
    margin-right: 0;
  }
  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + * {
    margin-top: 45px;
  }
  .classic-blog .post-content > .post-media:first-child,
  .classic-blog .owl-carousel.post-media + * {
    margin-top: 0;
  }
  .classic-blog .post-title {
    font-size: 33px;
  }
  .classic-blog .post-content .meta {
    padding-left: 50px;
    padding-right: 50px;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 25px;
  }
  .classic-blog.no-sidebar .sidemeta, .classic-blog.no-sidebar .pagination {
    left: auto;
  }
  .sidebar-left .grid-blog.col-2,
  .sidebar-left .grid-blog.col-2 + .pagination {
    padding-left: 20px;
    padding-right: 0;
  }
  .grid-blog.col-2 {
    padding-right: 20px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .grid-blog.col-2 .post {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
  .grid-blog.col-2 .post-title {
    font-size: 28px;
  }
  .grid-blog.col-2.no-sidebar,
  .grid-blog.col-3.no-sidebar {
    padding-right: 0;
    margin-left: -25px;
  }
  .grid-blog.col-2.no-sidebar .post,
  .grid-blog.col-3.no-sidebar .post {
    width: 347px;
    padding-bottom: 40px;
    margin-left: 25px;
    margin-bottom: 25px;
  }
  .grid-blog.col-2.no-sidebar .post-content > *,
  .grid-blog.col-3.no-sidebar .post-content > * {
    margin-left: 40px;
    margin-right: 40px;
  }
  .grid-blog.col-2.no-sidebar .post-content > *:first-child,
  .grid-blog.col-2.no-sidebar .post-media + *,
  .grid-blog.col-3.no-sidebar .post-content > *:first-child,
  .grid-blog.col-3.no-sidebar .post-media + * {
    margin-top: 35px;
  }
  .grid-blog.col-2.no-sidebar .post-content .meta,
  .grid-blog.col-3.no-sidebar .post-content .meta {
    padding-left: 40px;
    padding-right: 40px;
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 25px;
  }
  .grid-blog.col-2.no-sidebar .post-title ,
  .grid-blog.col-3.no-sidebar .post-title {
    font-size: 24px;
  }
  .grid-blog.col-2.no-sidebar p,
  .grid-blog.col-3.no-sidebar p {
    font-size: 16px;
  }
  .format-filter li a {
    padding: 0 25px;
  }
  .blog.no-sidebar .post,
  .blog.no-sidebar #comments,
  .blog.no-sidebar .pagination,
  .blog.no-sidebar .comment-form-wrapper {
    width: 100%;
  }
  .classic-blog .pagination,
  .classic-blog .comment-form-wrapper {
    margin-bottom: 120px;
  }
  .classic-blog.no-sidebar .pagination,
  .classic-blog.no-sidebar .comment-form-wrapper {
    margin-bottom: 0;
  }

  [class*="col-md"][class*="inner-left"],
  [class*="col-md"][class*="inner-right"] {
    padding-left: 15px;
    padding-right: 15px;
  }

  [class*="col-md"][class*="border-left"] {
    border-left: none;
  }

  [class*="col-md"][class*="border-right"] {
    border-right: none;
  }

  .tabs-circle-top .etabs:before {
    margin: 50px 0 -50px;
  }
  .tabs-circle-top .tab {
    height: 130px;
    margin: 0 5px;
  }
  .tabs-circle-top .tab a {
    width: 100px;
    height: 100px;
    max-width: 100px;
    font-size: 11px;
  }
  .tabs-circle-top .tab a div {
    font-size: 33px;
  }
  .tabs-circle-top .tab.active a {
    height: 130px;
    width: 130px;
    max-width: 130px;
    font-size: 13px;
  }
  .tabs-circle-top .tab.active a div {
    font-size: 44px;
  }

  #owl-main .caption.text-center,
  #owl-main .caption.text-left,
  #owl-main .caption.text-right {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-header .navbar-brand {
    display: inline-block;
  }
  .navbar-collapse .navbar-brand {
    display: none;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar .container {
    display: block;
  }

  .navbar .social,
  .navbar .info {
    display: none;
  }

  .navbar-header {
    background: #FFF;
    padding: 25px 0;
  }
  .btn.responsive-menu {
    display: table;
  }
  .btn.responsive-menu i {
    display: table-cell;
    vertical-align: middle;
  }
  .navbar-collapse {
    position: static !important;
    background: #F5F7FA;
    max-height: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-collapse:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .yamm .nav,
  .yamm .dropup,
  .yamm .dropdown,
  .yamm .collapse {
    float: none;
    position: relative;
  }
  .yamm .dropdown-menu {
    position: relative;
    left: inherit;
    top: inherit;
    float: none;
    width: 100%;
    /* display: block !important;*/
  }
  .yamm .yamm-content {
    padding: 12px 0;
    font-size: 13px;
  }
  .navbar-nav {
    float: none !important;
    display: block;
    padding: 15px 0;
  }
  .navbar-nav > li {
    float: none;
    display: block;
  }
  .navbar-nav > li + li {
    border-top: 1px solid #E6E9ED;
  }
  .navbar-nav > li > a {
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-radius: 0;
  }
  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus {
    background: none;
  }
  .navbar-nav .dropdown-menu {
    border: none;
    box-shadow: none;
  }
  .navbar-nav .dropdown-submenu .dropdown-menu {
    top: 0 !important;
    padding-left: 20px;
  }
  .navbar-nav .dropdown-menu > li:last-child {
    margin-bottom: 20px;
  }
  .navbar-nav .searchbox {
    float: left !important;
    display: block;
    width: 100%;
  }
  .navbar-header {
    float: left;
    width: 100%;
  }
  .navbar-header:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@media (max-width: 969px) {
  .yamm .dropdown.yamm-fullwidth .dropdown-menu {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 979px) {

  .navbar .container,
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .tabs-side .etabs {
    width: 240px;
  }
  .tabs-side.tab-container .panel-container {
    width: 480px;
  }
  .tabs-top.left .tab {
    margin-right: 2px;
  }
  .tabs-top.left .tab a {
    padding: 5px 10px;
  }
  .items.col-4 {
    margin-left: -9px;
    margin-bottom: -9px;
  }
  .items.col-4 li {
    width: 234px;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  .items.col-3 {
    margin-left: -9px;
    margin-bottom: -9px;
  }
  .items.col-3 li {
    width: 234px;
    margin-left: 9px;
    margin-bottom: 9px;
  }
  .items.col-3.gap {
    margin-left: -18px;
    margin-bottom: -18px;
  }
  .items.col-3.gap li {
    width: 351px;
    margin-left: 18px;
    margin-bottom: 18px;
  }
  .items.col-4.gap {
    margin-left: -18px;
    margin-bottom: -18px;
  }
  .items.col-4.gap li {
    width: 351px;
    margin-left: 18px;
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {

  /*body {
		height: 100vh;
	}*/

  h1,
  h2,
  h3,
  h4 {
    letter-spacing: .0125em;
  }

  h1 {
    font-size: 33px;
    line-height: 120%;
    margin-bottom: 20px;
  }

  header p {
    font-size: 20px;
  }

  footer .inner {
    padding-top: 35px;
    padding-bottom: 25px;
  }

  footer .row .inner {
    padding-left: 20px;
    padding-right: 20px;
  }

  footer .container > .row {
    margin-left: -20px;
    margin-right: -20px;
  }

  .row,
  #share {
    overflow: hidden;
  }

  #share .container {
    width: auto;
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  /*.owl-controls {
		display: inline;
	}

	.owl-pagination {
		top: 0 !important;
	}*/

  .last-bottom {
    padding-bottom: 20px !important;
  }

  .last-bottom + .last-bottom {
    padding-bottom: 0 !important;
  }

  .thumbs.gap-lg {
    margin-left: -15px;
  }

  .thumbs.gap-lg .thumb {
    padding: 0 0 15px 15px;
  }

  .icon.pull-right {
    margin-left: 10px;
  }

  .inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .inner-md {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .inner-sm {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .inner-xs {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .inner-top {
    padding-top: 80px;
  }
  .inner-top-md {
    padding-top: 65px;
  }
  .inner-top-sm {
    padding-top: 50px;
  }
  .inner-top-xs {
    padding-top: 40px;
  }
  .inner-bottom {
    padding-bottom: 80px;
  }
  .inner-bottom-md {
    padding-bottom: 65px;
  }
  .inner-bottom-sm {
    padding-bottom: 50px;
  }
  .inner-bottom-xs {
    padding-bottom: 40px;
  }

  .height-xs,
  .height-sm,
  .height-md,
  .height-lg,
  #owl-main .item,
  #owl-main.height-md .item,
  #owl-main.height-lg .item {
    height: 88vh;
  }

  #hero h1 {
    font-size: 38px;
    line-height: 115%;
    letter-spacing: 0.031em;
  }

  #hero p {
    font-size: 20px;
    line-height: 135%;
    margin-top: 15px;
  }

  #hero #owl-main .caption h1.light-bg,
  #hero #owl-main .caption h1.medium-bg,
  #hero #owl-main .caption h1.dark-bg,
  #hero #owl-main .caption h1.tint-bg {
    font-size: 33px;
  }

  #hero .btn {
    margin-top: 25px;
  }

  #hero .btn-large {
    font-size: 16px;
    padding: 12px 30px 15px;
  }

  .retina-icons-code tr td:first-child,
  .retina-icons-code tr th:first-child {
    max-width: 30px;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    line-height: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }

  .table > thead > tr > th {
    font-size: 11px;
  }

  .retina-icons-code i {
    font-size: 14px;
  }

  .retina-icons-code code {
    font-size: 10px;
  }

  .tabs-side .etabs {
    float: none;
    width: 100%;
  }

  .tabs-side .tab a {
    border-right-color: #E6E9ED;
  }

  .tabs-side .tab:first-child a {
    border-top-right-radius: 3px;
  }

  .tabs-side .tab:last-child a {
    border-bottom-right-radius: 3px;
  }

  .tabs-side .tab.active a {
    border-right-color: #CED7E0 !important;
  }

  .tabs-side.tab-container .panel-container {
    padding-left: 0;
    border-left: none;
    margin-top: 30px;
  }

  .tabs-top .etabs:after {
    display: none;
  }
  .tabs-top .tab {
    display: block;
    max-width: 100%;
    margin: 0;
  }
  .tabs-top .tab a {
    display: block;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .tabs-top .tab:first-child a {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .tabs-top .tab:last-child a {
    border-bottom: 1px solid #E6E9ED;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .tabs-top .tab.active + .tab a {
    border-top-color: #CED7E0;
  }
  .tabs-top .tab.active + .tab a:hover {
    border-top-color: $green;
  }

  .filter:before, .format-filter:before {
    display: none;
  }

  h1.single-block .btn {
    margin-left: 0;
  }

  .sidemeta .date-wrapper,
  .classic-blog.sidebar-left .sidemeta .date-wrapper {
    top: -80px;
    left: 50%;
    margin-left: -60px;
  }

  .sidemeta .format-wrapper,
  .classic-blog.sidebar-left .sidemeta .format-wrapper {
    top: -70px;
    left: 50%;
    margin-left: 10px;
  }

  .classic-blog .sidemeta,
  .classic-blog .pagination {
    padding-left: 0;
    border-left: none;
    margin-left: 0;
  }
  .classic-blog.sidebar-left .sidemeta,
  .classic-blog.sidebar-left .pagination {
    padding-right: 0;
    border-right: none;
    margin-right: 0;
  }
  .classic-blog.no-sidebar .sidemeta,
  .classic-blog.no-sidebar .pagination {
    left: 0;
  }
  .classic-blog .post {
    margin-top: 80px;
    width: 100%;
  }

  .grid-blog.col-2 {
    padding-right: 0;
  }

  .sidebar-left .grid-blog.col-2,
  .sidebar-left .grid-blog.col-2 + .pagination {
    padding-left: 0;
  }

  .classic-blog .post,
  .grid-blog.col-2 .post,
  .grid-blog.col-3 .post,
  .grid-blog.col-2.no-sidebar .post,
  .grid-blog.col-3.no-sidebar .post {
    padding-bottom: 30px;
  }

  .classic-blog .post-content > *,
  .grid-blog.col-2 .post-content > *,
  .grid-blog.col-3 .post-content > *,
  .grid-blog.col-2.no-sidebar .post-content > *,
  .grid-blog.col-3.no-sidebar .post-content > * {
    margin-left: 30px;
    margin-right: 30px;
  }

  .classic-blog .post-content .meta,
  .grid-blog.col-2 .post-content .meta,
  .grid-blog.col-3 .post-content .meta,
  .grid-blog.col-2.no-sidebar .post-content .meta,
  .grid-blog.col-3.no-sidebar .post-content .meta {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
  }

  .classic-blog .post-content > *:first-child,
  .classic-blog .post-media + *,
  .grid-blog.col-2 .post-content > *:first-child,
  .grid-blog.col-2 .post-media + *,
  .grid-blog.col-3 .post-content > *:first-child,
  .grid-blog.col-3 .post-media + *,
  .grid-blog.col-2.no-sidebar .post-content > *:first-child,
  .grid-blog.col-2.no-sidebar .post-media + *,
  .grid-blog.col-3.no-sidebar .post-content > *:first-child,
  .grid-blog.col-3.no-sidebar .post-media + * {
    margin-top: 30px;
  }

  .classic-blog .post-title,
  .grid-blog.col-2 .post-title,
  .grid-blog.col-3 .post-title,
  .grid-blog.col-2.no-sidebar .post-title,
  .grid-blog.col-3.no-sidebar .post-title {
    font-size: 24px;
  }

  .classic-blog p,
  .grid-blog.col-2 p,
  .grid-blog.col-3 p,
  .grid-blog.col-2.no-sidebar p,
  .grid-blog.col-3.no-sidebar p {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 10px;
  }

  .classic-blog .pagination,
  .classic-blog .comment-form-wrapper,
  .pagination {
    margin-bottom: 100px;
  }

  .grid-blog.col-2.no-sidebar + .pagination,
  .grid-blog.col-3.no-sidebar + .pagination {
    margin-bottom: 0;
  }

  .classic-blog .pagination,
  .classic-blog.sidebar-left .pagination,
  .grid-blog.col-2 + .pagination,
  .grid-blog.col-3 + .pagination,
  .grid-blog.col-2.no-sidebar + .pagination,
  .grid-blog.col-3.no-sidebar + .pagination {
    margin-left: -10px;
  }

  .pagination > li:first-child {
    margin-left: 10px;
  }

  .pagination li {
    margin-bottom: 10px;
  }

  #blog-post .post h2 {
    font-size: 19px;
    margin-bottom: 15px;
  }

  #blog-post .post h3 {
    font-size: 16px;
  }

  #blog-post .post h4 {
    font-size: 14px;
  }

  #blog-post .post figure figcaption p {
    font-size: 14px;
    line-height: 150%;
  }

  [class*="col"] > figcaption:first-child {
    padding-top: 20px;
  }

  .post-author, #comments {
    padding: 30px;
  }

  .post-author .author-image {
    float: none;
    display: inline-block;
    margin-bottom: 20px;
  }

  .post-author .author-details {
    padding-left: 0;
  }

  #comments .avatar {
    float: none;
    display: inline-block;
    margin-bottom: 15px;
  }

  #comments .commentbody {
    padding-left: 0;
  }

  #comments .commentlist .children {
    padding-left: 0;
  }

  [class*="col"][class*="border-left"] {
    border-left: none;
  }

  [class*="col"][class*="border-right"] {
    border-right: none;
  }

  .filter li {
    display: block;
  }

  .filter li + li {
    margin-top: 15px;
  }

  .filter li:after {
    display: none;
  }

  #hero .info,
  #hero .info,
  #hero .info,
  .items li .text-overlay .info.big,
  .thumbs .thumb .text-overlay .info.big,
  .owl-carousel .item .text-overlay .info.big {
    margin-top: -25px;
  }
  .items li .text-overlay .info h2,
  .thumbs .thumb .text-overlay .info h2,
  .owl-carousel .item .text-overlay .info h2,
  .items li .text-overlay .info h3,
  .thumbs .thumb .text-overlay .info h3,
  .owl-carousel .item .text-overlay .info h3,
  .items li .text-overlay .info h4,
  .thumbs .thumb .text-overlay .info h4,
  .owl-carousel .item .text-overlay .info h4 {
    font-size: 16px;
    line-height: 150%;
  }
  .items li .text-overlay .info p,
  .thumbs .thumb .text-overlay .info p,
  .owl-carousel .item .text-overlay .info p,
  .items li .text-overlay .info.big p,
  .thumbs .thumb .text-overlay .info.big p,
  .owl-carousel .item .text-overlay .info.big p,
  #hero .items li .text-overlay .info.big p,
  #hero .thumbs .thumb .text-overlay .info.big p,
  #hero .owl-carousel .item .text-overlay .info.big p {
    font-size: 14px;
    line-height: 140%;
    padding-top: 0;
  }

  .tabs-2-big-top .tab {
    width: 100%;
    margin: 0 0 50px 0;
  }
  .tabs-2-big-top .tab a {
    padding: 25px;
  }
  .tabs-2-big-top.tab-container .panel-container {
    margin-top: 0;
  }

  [class*="col"][class*="inner-left"],
  [class*="col"][class*="inner-right"],
  [class*="col"][class*="reset-padding"] {
    padding-left: 15px;
    padding-right: 15px;
  }

  [class*="inner-top"].reset-xs {
    padding-top: 0 !important;
  }

  .tabs-circle-top .etabs:before {
    border-bottom: none;
    margin: 0;
  }
  .tabs-circle-top .tab {
    display: table;
    width: 100%;
    height: auto;
    margin: 0;
  }
  .tabs-circle-top .tab.active {
    top: 0;
  }
  .tabs-circle-top .tab a,
  .tabs-circle-top .tab.active a {
    width: 100%;
    max-width: 100%;
    height: auto;
    font-size: 18px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: none;
    border-radius: 0;
  }
  .tabs-circle-top .tab a:hover {
    border-width: 1px;
    border-color: #CED7E0 !important;
    border-top: none;
  }
  .tabs-circle-top .tab.active a:hover {
    border-color: transparent !important;
  }
  .tabs-circle-top .tab:first-child a {
    border-top: 1px solid #CED7E0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .tabs-circle-top .tab:last-child a {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .tabs-circle-top .tab a div,
  .tabs-circle-top .tab.active a div {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    line-height: 120%;
    padding-bottom: 5px;
    margin: 0 10px 0 0;
  }

  .yamm-content {
    margin-top: 25px;
  }

  .yamm-content > [class*="col"] {
    margin-bottom: 50px;
  }
  .navbar .container,
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .footer-bottom p.pull-left,
  .footer-bottom ul.pull-right {
    float: none !important;
    text-align: center;
  }
  .footer-bottom p {
    margin-bottom: 20px;
  }
  .footer-menu li {
    padding: 0 5px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .filter li a:before {
    display: none;
  }
  .items {
    margin-left: 0 !important;
    /*margin-bottom: -20px !important;*/
  }
  .items li {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .items.fullscreen li {
    margin: 0 !important;
  }
  .sidebar {
    margin-top: 70px;
    padding-left: 15px !important;
  }
  .blog.no-sidebar .post,
  .blog.no-sidebar #comments,
  .blog.no-sidebar .pagination,
  .blog.no-sidebar .comment-form-wrapper {
    width: 100%
  }
  .blog.no-sidebar .pagination ul {
    margin: 0
  }
  .grid-blog {
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  .grid-blog .post {
    width: 100% !important;
    margin-left: 0 !important;
    margin-bottom: 20px !important;
  }
  #comments ul.children {
    padding: 0 0 0 20px;
  }
  #comments .arrow-box {
    margin-left: 0;
  }
  .panel-title {
    line-height: 18px;
  }
  .yamm .yamm-content.row {
    margin-bottom: -30px
  }
  .modal .container {
    padding-left: 35px;
    padding-right: 35px;
  }
  .modal-dialog {
    top: 25px;
    bottom: 25px;
    left: 25px;
    right: 25px;
    width: calc(100% - 50px);
  }
  .modal-open {
    background-color: #F2F5F7;
  }
}

@media (min-width: 768px) {
  .modal-open {
    margin-left: -17px;
    margin-right: -17px;
  }
  .modal-open .navbar-collapse.affix {
    padding-right: 32px;
    right: 0;
  }
}

@media (min-width: 768px) and (max-height: 1024px) {

  .height-xs,
  .height-sm,
  #owl-main .item {
    height: 480px;
  }

  #hero h1 {
    font-size: 60px;
    line-height: 115%;
  }

  #hero p {
    font-size: 22px;
    line-height: 135%;
    margin-top: 17px;
  }

  #hero #owl-main .caption h1.light-bg,
  #hero #owl-main .caption h1.medium-bg,
  #hero #owl-main .caption h1.dark-bg,
  #hero #owl-main .caption h1.tint-bg {
    font-size: 50px;
  }

  #hero .btn {
    margin-top: 35px;
  }

  #hero .btn-large {
    font-size: 16px;
    padding: 15px 35px 17px;
  }
}

@media (min-width: 768px) and (max-height: 768px) {

  .height-xs,
  .height-sm,
  .height-md,
  .height-lg,
  #owl-main .item,
  #owl-main.height-md .item,
  #owl-main.height-lg .item {
    height: 480px;
  }

}

@media (max-width: 480px) {
  .modal .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-dialog {
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
  }
  .modal-header {
    padding: 14px 15px;
  }
  .modal-title {
    font-size: 16px;
    line-height: 120%;
  }
}


/*===================================================================================*/
/*	DEMO ONLY
/*===================================================================================*/

a.changecolor {
  cursor: pointer;
}


/*===================================================================================*/
/*	MEDIA QUERIES | BREAKPOINTS
/*===================================================================================*/

body:before {
  content: 'xs';
  display: none;
}

/*@media (min-width: 768px) {
	body:before {
		content: 'sm';
	}
}*/

@media (min-width: 1025px) {
  body:before {
    content: 'md';
  }
}

/*@media (min-width: 1200px) {
	body:before {
		content: 'lg';
	}
}*/


/*===================================================================================*/
/*	DEMO ONLY
/*===================================================================================*/

a.changecolor {
  cursor: pointer;
}