#hero {
	p {
		font-size: 20px;
		@media (min-width: 768px) {
			font-size: 30px;
		}
	}
	.item {
		position: relative;
		hero {
			position: relative;
			line-height: 50px;
		}
	}
}

#owl-main .caption.text-center {
	padding-left: 0;
	padding-right: 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $black;
	opacity: 0.4;
}


.hero {
	font-size: 60px;
	line-height: 115%;
	font-weight: 900;
	letter-spacing: .05em;
	text-transform: uppercase;
	margin-bottom: 0;
	transition: font 200ms ease-out;
	color: $white;
	font-family: 'Pontiac Regular', sans-serif;
}