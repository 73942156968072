.navbar-brand {
  img {
    height: 50px;
    @media (min-width: 1200px) {
      height: 125px;
    }
  }
}

.navbar-collapse {
  padding-top: 5px;
  padding-bottom: 10px !important;
}

.navbar-nav {
  float: right;
  padding: 30px;
  > li {
    > a {
      font-size: 14px;
      font-weight: 600;
      color: #585858
    }
    .dropdown-menu {
      position: static;
      float: none;
      @media (min-width: 1026px) {
        position: absolute;
        float: left;
      }
    }
  }
}