// ============================================================================
// Blog Stylesheet
// ============================================================================

.post-media {
	img.post-img {
		width: auto;
		margin: 0 auto;
		padding: 15px;
	}
}