// ============================================================================
// Sane Defaults for popular frameworks & libraries we use
// ============================================================================

// Lightgallery
.lg-backdrop {
  //background-color: ;
}

.lg-progress-bar {
  .lg-progress {
    // background-color: ;
  }
}

.lg-toolbar {
  // background-color: ;
}

.lg-outer {
  .lg-thumb-item {
    border: 0;
    border-radius: 0;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }
}

// Silverstripe Search
.highlight {
  font-weight: bold;
}

.bookingForm {
  height: 815px;
  width: 100%;
  position: relative;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-style: none;
  }
}