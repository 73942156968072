.padding-bot-20 {
  padding-bottom: 20px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-60 {
  padding-top: 60px;
}